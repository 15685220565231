const largerRegions = [
  "USA",
  "CAN",
  "RUS",
  "CHN",
  "BRA",
  "AUS",
  "IND",
  "ARG",
  "KAZ",
  "DZA",
  "GRL",
  "COD",
  "SAU",
  "MEX",
  "IDN",
  "SDN",
  "LBY",
  "IRN",
  "MNG",
  "PER",
];
/* HELPER FUNCTIONS */

// these export functions will help determine if a user clicks a new admin 1 region (country)
export function getAdmin0Code(gid) {
  return gid.substring(0, 3);
}
// the first three characters of a GID will always be the admin 1 region (country)
export function isSameAdmin0(g1, g2) {
  return getAdmin0Code(g1) === getAdmin0Code(g2);
}

/* GEOJSON SOURCE & LAYER FUNCTIONS */

// creates source for geojson (layer will need the source)
export async function addGeoJsonSource(map, geojson, source) {
  try {
    await map.addSource(source, {
      type: "geojson",
      data: geojson,
    });
    return;
  } catch (e) {
    console.log(`Geojson unable to load ${source}, try again`, e);
  }
}

// adds an outline to access data on selected item
export async function outlineLayer(map, source, layer, color) {
  if (await map.getLayer(layer)) {
    await map.removeLayer(layer);
  }
  await map.addLayer({
    id: layer,
    source: source,
    type: "line",
    paint: {
      "line-color": color,
      "line-width": 2,
      "line-opacity-transition": { duration: 1000 },
    },
  });
}

// adds a transparent layer to access data on click
export async function transparentLayer(map, source, layer) {
  if (map.getLayer(layer)) {
    await map.removeLayer(layer);

    if (map.getLayer(layer + "-border")) {
      await map.removeLayer(layer + "-border");
    }
  }
  await map.addLayer({
    id: layer,
    type: "fill",
    source: source,
    paint: {
      "fill-color": "transparent",
      "fill-opacity": 1,
    },
  });
  await map.addLayer({
    id: layer + "-border",
    source: source,
    type: "line",
    paint: {
      "line-color": "black",
      "line-width": 1,
      "line-opacity": 0.4,
    },
    interactive: false, // Make the layer non-clickable
  });
}

export async function removeSourceLayer(map, layer, source) {
  if (map.getLayer(layer)) {
    await map.removeLayer(layer);

    if (map.getLayer(layer + "-border")) {
      await map.removeLayer(layer + "-border");
    }
  }

  if (map.getSource(source) && layer !== "selected-outline") {
    await map.removeSource(source);
  }

  return;
}

export function setLayerOrder(map) {
  if (map.getSource("country-boundaries-layer")) {
    map.moveLayer("country-boundaries-layer", null);
  }
  if (map.getSource("admin-0-layer")) {
    map.moveLayer("admin-0-layer", null);
  }
  if (map.getSource("admin-1-layer")) {
    map.moveLayer("admin-1-layer", null);
  }
  if (map.getSource("admin-2-layer")) {
    map.moveLayer("admin-2-layer", null);
  }
}

/* FUNCTIONS TO HELP MOVE THE VIEW */

// Function to calculate the center of the bounding box for multiple rings
function calculateCenterCoordinates(coordinates) {
  let minX = Infinity;
  let maxX = -Infinity;
  let minY = Infinity;
  let maxY = -Infinity;

  coordinates.forEach((c) => {
    minX = Math.min(minX, c[0]);
    maxX = Math.max(maxX, c[0]);
    minY = Math.min(minY, c[1]);
    maxY = Math.max(maxY, c[1]);
  });

  const centerX = (minX + maxX) / 2;
  const centerY = (minY + maxY) / 2;

  return [centerX, centerY];
}

export function moveToCenterOfLayer(map, coordinates, gid) {
  // Calculate the center for multiple rings
  let centerCoords = calculateCenterCoordinates(coordinates);

  moveToCoordinates(map, centerCoords);
}

export function moveToCoordinates(map, coordinates, gid) {
  map.flyTo({
    center: coordinates,
    zoom: largerRegions.includes(gid) ? 3 : 5,
    duration: 800,
    essential: true, // This animation is considered essential with
    //respect to prefers-reduced-motion
  });
}

export async function getAdmin0CenterCoordinates(gid) {
  var data = await getAdmin0Data(gid);

  return [data.center_longitude, data.center_latitude];
}

export async function getCoordinatesFromGeojson(url) {
  return fetch(url)
    .then((response) => response.json())
    .then((data) => {
      const coordinates = [];
      data.features.forEach((feature) => {
        feature.geometry.coordinates.forEach((coord) => {
          let c = flattenAndPairCoordinates(coord);
          coordinates.push(...c);
        });
      });

      return coordinates;
    });
}

// flattens array of coordinates
function flattenCoordinatesArray(arr) {
  return arr.reduce((acc, val) => {
    return Array.isArray(val)
      ? acc.concat(flattenCoordinatesArray(val))
      : acc.concat(val);
  }, []);
}

function flattenAndPairCoordinates(arr) {
  const flattenedCoordinates = flattenCoordinatesArray(arr);
  const pairedCoordinates = [];

  for (let i = 0; i < flattenedCoordinates.length; i += 2) {
    pairedCoordinates.push([
      flattenedCoordinates[i],
      flattenedCoordinates[i + 1],
    ]);
  }

  return pairedCoordinates;
}

/* END POINTS */
const apiVersion = "/api/v1";
const jmrvEndpoint = "/jmrv/data_categorized";
const jmrvPercent = "/jmrv/data_baseline";

export async function getAdmin0Data(gid) {
  //console.log(`/api/v1/gis/admin_0?gid_0=${getAdmin0Code(gid)}`);
  const response = await fetch(`/api/v1/gis/admin_0?gid_0=${getAdmin0Code(gid)}`);
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}
export async function getAdmin1Url(gid) {
  //console.log(`/api/v1/gis/admin_1?gid_1=${gid}`)
  const response = await fetch(`/api/v1/gis/admin_1?gid_1=${gid}`);
  if (response.status === 200) {
    const data = await response.json();
    return data.url;
  } else {
    return;
  }
}
export async function getAdmin0Children(gid) {
  //console.log(`/api/v1/gis/admin_0_children?gid_0=${getAdmin0Code(gid)}`);
  const response = await fetch(
    `/api/v1/gis/admin_0_children?gid_0=${getAdmin0Code(gid)}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data.url;
  } else {
    return;
  }
}
export async function getJmrvAdmin0Data(gid) {
  //console.log(`${apiVersion}${jmrvEndpoint}?admin_level=0&gid_value=${gid}`)
  const response = await fetch(
    `${apiVersion}${jmrvEndpoint}?admin_level=0&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}
export async function getJmrvAdmin1Data(gid) {
  //console.log(`${apiVersion}${jmrvEndpoint}?admin_level=1&gid_value=${gid}`)
  const response = await fetch(
    `${apiVersion}${jmrvEndpoint}?admin_level=1&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}
export async function getJmrvAdmin0Percent(gid) {
  //console.log(`${apiVersion}${jmrvPercent}?admin_level=0&gid_value=${gid}`)
  const response = await fetch(
    `${apiVersion}${jmrvPercent}?admin_level=0&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}
export async function getJmrvAdmin1Percent(gid) {
  //console.log(`${apiVersion}${jmrvPercent}?admin_level=1&gid_value=${gid}`)
  const response = await fetch(
    `${apiVersion}${jmrvPercent}?admin_level=1&gid_value=${gid}`
  );
  if (response.status === 200) {
    const data = await response.json();
    return data;
  } else {
    return;
  }
}
