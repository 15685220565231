import React from "react";
import { DownloadBtn, ProgressBar } from "../Charts/components";

const SubSection = ({ description, chart, breakdownTitle, breakdownSubtitle, progressBar, colors, gid, category, adminLevel }) => {
  return (
    <div className="mb-1 max-w-[410px]">
      <p className="text-sm">{description}</p>
      <div className="w-full mx-auto mb-2">{chart}</div>
      <div>
        <div className="font-semibold text-md">{breakdownTitle}</div>
        <div className="text-sm mb-1">{breakdownSubtitle}</div>
        {Object.keys(progressBar).map((value, index) => (
          <div key={index}>
            <ProgressBar
              title={colors[value].display}
              percentage={progressBar[value].percent}
              unit={progressBar[value].unit}
              value={progressBar[value].value}
              color={colors[value].color}
            />
          </div>
        ))}
      </div>
      <DownloadBtn gid={gid} adminLevel={adminLevel} category={category} />
    </div>
  );
};

export default SubSection;
