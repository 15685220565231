import React from "react";
import { ExpandableSection, SubSection, Chart } from "./components";
import {
    panelArea,
    panelCarbonStock,
    panelEmissions,
    panelRemovals,
} from "../../../constants";
import { legendItems } from "./components/Charts/constants";
import { getUnits, getAverageOfCategoryOverTime } from "./helpers";

export default function Panel({
    gid,
    adminLevel,
    data,
    percentData,
    setPercentData,
    initialChartRange,
    units,
    setUnits,
    xAxisLimits,
    setXAxisLimits,
    openModal,
}) {
    const barData = [];
    if (data) {
        data.area[0].values.forEach((d, i) => {
            const year = d.year;
            const values = {
                year,
                area_total: getBarValue(year, "area_total"),
                area_non_forest: getBarValue(year, "area_non_forest"),
                area_forest: getBarValue(year, "area_forest"),
            };
            barData.push(values);
        });
    }

    function getBarValue(year, category) {
        let result;
        data.area.forEach((key) => {
            if (key.name === category) {
                key.values.forEach((value) => {
                    if (value.year === year) {
                        result = value[units.area];
                    }
                });
            }
        });
        return result;
    }

    // Callback function to handle the selected range
    const onFilterChange = (id, newRange) => {
        const newXAxis = { ...xAxisLimits, [id]: newRange };
        const newUnits = getUnits(data, newXAxis);

        setUnits(newUnits);
        setXAxisLimits(newXAxis); // Update the range in the parent component

        const newAverages = getAverageOfCategoryOverTime(
            data,
            id,
            newRange,
            newUnits[id],
        );
        setPercentData({ ...percentData, [id]: newAverages });
    };

    let chartProps = {
        onFilterChange,
        units,
        data,
        initialChartRange,
        xAxisLimits,
    };

    const chartType = {
        removals: "removal",
        emissions: "emission",
        area: "area",
        carbonStock: "carbon_stock",
    };

    const areaProps = { ...chartProps, barData, dataType: chartType.area };
    const carbonStockProps = { ...chartProps, dataType: chartType.carbonStock };
    const emissionsProps = { ...chartProps, dataType: chartType.emissions };
    const removalsProps = { ...chartProps, dataType: chartType.removals };

    const charts = [
        {
            title: panelArea.title,
            modalContent: panelArea.infoTooltip,
            description: panelArea.desc,
            chart: <Chart {...areaProps} />,
            breakdownTitle: panelArea.titleProgressBars,
            progressBar: percentData?.area,
            colors: legendItems.area,
            category: "area",
        },
        {
            title: panelCarbonStock.title,
            modalContent: panelCarbonStock.infoTooltip,
            description: panelCarbonStock.desc,
            chart: <Chart {...carbonStockProps} />,
            breakdownTitle: panelCarbonStock.titleProgressBars,
            breakdownSubtitle: panelCarbonStock.subtitleProgressBars,
            progressBar: percentData?.carbon_stock,
            colors: legendItems.carbon_stock,
            category: "carbon_stock",
        },
        {
            title: panelEmissions.title,
            modalContent: panelEmissions.infoTooltip,
            description: panelEmissions.desc,
            chart: <Chart {...emissionsProps} />,
            breakdownTitle: panelEmissions.titleProgressBars,
            breakdownSubtitle: panelCarbonStock.subtitleProgressBars,
            progressBar: percentData?.emission,
            colors: legendItems.emission,
            category: "emission",
        },
        {
            title: panelRemovals.title,
            modalContent: panelRemovals.infoTooltip,
            description: panelRemovals.desc,
            chart: <Chart {...removalsProps} />,
            breakdownTitle: panelRemovals.titleProgressBars,
            breakdownSubtitle: panelRemovals.subtitleProgressBars,
            progressBar: percentData?.removal,
            colors: legendItems.removal,
            category: "removal",
        },
    ];

    return (
        <div
            id="jmrvPanel"
            className="z-50 h-full w-full overflow-y-auto overflow-x-hidden bg-white lg:absolute lg:h-auto lg:max-h-screen lg:w-fit lg:bg-transparent lg:bg-none lg:p-4 lg:pr-2"
            style={{ scrollbarGutter: "stable" }}
        >
            {data ? (
                charts.map((chart) => {
                    const subSectionProps = {
                        description: chart.description,
                        chart: chart.chart,
                        breakdownTitle: chart.breakdownTitle,
                        progressBar: chart.progressBar,
                        colors: chart.colors,
                        category: chart.category,
                        gid: gid,
                        adminLevel: adminLevel,
                    };
                    return (
                        <ExpandableSection
                            key={chart.title}
                            title={chart.title}
                            openModal={openModal}
                            modalContent={chart.modalContent}
                        >
                            <SubSection {...subSectionProps} />
                        </ExpandableSection>
                    );
                })
            ) : (
                <div className="my-4">No data available for this region.</div>
            )}
        </div>
    );
}
