import { height } from "../../constants";
import { scaleLinear } from "d3-scale";

const d3 = { scaleLinear };

export default function getYScale(maxYValue) {
    return d3
        .scaleLinear()
        .domain([0, maxYValue + 5]) // Replace 'maxYValue' with the maximum value in your data
        .range([height, 0]) // 'height' is the height of your chart's drawing area
        .nice(); // This ensures nice, human-friendly ticks
}
