import { PrimaryBtn, OpacityWrapper } from "@components";
import { productList } from "@helpers";
import { overlayBlocks } from "@helpers/constants";
import { fullWidthSubHeader } from "..";

export default function Products() {
  return (
    <>
      <div className={fullWidthSubHeader + " md:text-4xl text-3xl w-full"}>Products</div>
      <div className="flex flex-col md:text-start text-center w-full md:w-overlines bg-white md:pb-16">
        {productList.map((prod, i) => {
          const props = { ...prod, i };
          return <ImgTextBlock key={i} {...props} />;
        })}
      </div>
    </>
  );
}

function ImgTextBlock({ imgLeft = true, imgURL, title, desc, btnLink, i }) {

  const Img = () => (
    <div className={`flex items-center h-[200px] w-full md:pb-0 md:w-1/2 md:h-[460px] overflow-hidden relative`} >
      <img src={imgURL}
        className='object-cover w-full h-full'
        loading='lazy'
      />
      <img src={overlayBlocks[i + 1]} className="w-full z-10 absolute -bottom-[1px]" />
    </div>
  );

  const btn = {
    link: btnLink,
    className: "md:w-full md:m-0 mt-10 mb-20",
  };

  const titleStyle =
    "md:text-4xl text-3xl mt-10 md:mt-0 md:text-5xl font-extrabold text-center";
  const descStyle = "md:text-lg mt-6 mb-10 text-center";

  return (
    <>
      <OpacityWrapper className={desktopContainer}>
        <div className="flex items-center w-[96%] border-[1px] border-black/[10%]">
          {imgLeft && <Img />}
          <div className="flex justify-center items-center w-1/2">
            <div className="flex flex-col items-center max-w-[400px]">
              <div className={titleStyle}>{title}</div>
              <div className={descStyle}>{desc}</div>
              <PrimaryBtn {...btn}>Learn More</PrimaryBtn>
            </div>
          </div>
          {!imgLeft && <Img />}
        </div>
      </OpacityWrapper>

      <OpacityWrapper className={mobileContainer}>
        <div className="px-4 py-10">
          <div className="border-black/[10%] border-[1px] w-full flex flex-col items-center">
            <Img />
            <div className="flex flex-col items-center mx-4">
              <div className={titleStyle}>{title}</div>
              <div className={descStyle}>{desc}</div>
              <PrimaryBtn {...btn}>Learn More</PrimaryBtn>
            </div>
          </div>
        </div>
      </OpacityWrapper>
    </>
  );
}

const desktopContainer =
  "md:flex hidden w-full flex-row last:mb-0 first:mt-20 justify-center items-center mb-20 my-8";
const mobileContainer =
  "md:hidden first:border-t-0 justify-center items-center";
