import React from "react";
import jmrv_carbon_legend from "@assets/jmrv_carbon_legend.png";

const GradientLegend = ({ text }) => {
  return (
    <div className="-w-fit h-fit pt-14 justify-center bg-white relative rounded-xl flex flex-col items-center">
      {text && (
        <div className="mb-36 w-32 -rotate-90 text-right text-mediumGray text-xs bg-white rounded-xl p-1.5 flex-shrink-0">
          {text}
        </div>
      )}
      <div
        className="-rotate-90 w-52 h-3.5 rounded-lg bg-cover m-2"
        style={{ backgroundImage: `url(${jmrv_carbon_legend})` }}
      />
      <div className="mt-24 -rotate-90 text-right text-mediumGray text-xs p-1.5 flex-shrink-0">
        0
      </div>
    </div>
  );
};

export default GradientLegend;
