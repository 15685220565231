import React from "react";

export default function Button({
  openModal,
  icon = "i",
  text,
  svgIcon, // this will determine if the icon is a svg or character
  backgroundColor, // when this is set, there is a unique color background to the button
  modalContent,
}) {
  const iconContainerStyles =
    "w-5 h-5 p-2.5 rounded-[100px] border border-black flex-col justify-center items-center inline-flex";

  return (
    <>
      {" "}
      {/* This button logic works for the button designs. */}
      {backgroundColor ? (
        <button
          onClick={(event) => openModal(event, modalContent)}
          style={{ backgroundColor: backgroundColor }}
          className={`w-fit p-2 mt-2 rounded-lg`}
        >
          <img className="lg:hidden" src={svgIcon} />
          <div className="text-black text-sm hidden lg:block">{text}</div>
        </button>
      ) : (
        <button
          onClick={(event) => openModal(event, modalContent)}
          className="text-white rounded inline-flex items-center"
        >
          <div className="lg:block hidden text-center text-black text-sm underline ml-2">
            {text}
          </div>
          <div className="w-8 h-8 bg-white lg:bg-transparent rounded-lg justify-center items-center gap-5 inline-flex">
            {svgIcon ? (
              <img src={svgIcon} />
            ) : (
              <div
                className={iconContainerStyles}
                onClick={(event) => openModal(event, modalContent)}
              >
                <div className="text-black text-sm font-semibold">{icon}</div>
              </div>
            )}
          </div>
        </button>
      )}
    </>
  );
}
