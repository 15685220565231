import { margin } from "../../constants";
import { select } from "d3-selection";
const d3 = { select };

export default function addLabelsToYAxis(svg, yAxis) {
    // Modify the y-axis to show labels only on the grid lines
    return svg
        .append("g")
        .attr("class", "y-axis")
        .attr("transform", `translate(${margin.left}, 0)`)
        .call(yAxis)
        .selectAll("text")
        .attr("class", "text-black")
        .attr("dy", "0.3em")
        .each(function () {
            const labelWidth = this.getBBox().width;
            d3.select(this).attr("dx", -labelWidth - 2);
        })
        .style("text-anchor", "start")
        .style("font-size", "12px");
}
