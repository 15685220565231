import { height } from "../../constants";
import { scaleLinear } from "d3-scale";

const d3 = {
    scaleLinear,
};

export default function getYScale(minYValue, maxYValue, padding) {
    return d3
        .scaleLinear()
        .domain([
            Math.min(0, minYValue) - padding, // Adjusted lower bound
            Math.max(0, maxYValue) + padding, // Adjusted upper bound
        ])
        .nice()
        .range([height, 0]);
}
