import { PageHeader, MetaTagHeader } from '@components'
import { beTagsFlat } from '@helpers/constants';
import { routesFlat } from "@routes";
import {
  FeatsAndBenefits,
  Method,
  ExploreTitleAndDesc,
  ProductNews,
} from "../../components";
import { header, featsAndBenefits, desc, superscript } from "./constants";

const metaProps = {
  title: "Measure tropical deforestation and forest degradation with CTrees",
  desc: "CTrees activity datasets quantify deforestation, forest degradation, and forest regeneration across the tropics. Contact us for licensing and partnerships.",
  img: "",
};

export default function Activity() {
  const pageHeader = { ...header, btnLink: routesFlat.contact, product: true };
  const method = { desc, superscript };
  const exploreProps = {
    ctaText: "Activity datasets are available by license.",
    link: routesFlat.contact,
  };

  return (
    <>
      <MetaTagHeader {...metaProps} />
      <PageHeader {...pageHeader} />
      <ExploreTitleAndDesc {...exploreProps}>
        <div className="mb-4">
          CTrees’ activity datasets measure deforestation, forest degradation,
          and forest regeneration across the tropics. Developed with
          first-of-its-kind AI technology, the datasets offer precise
          attribution, identifying deforestation and forest degradation from
          selective and illegal logging, fire, and other activities with {">"}
          90% overall accuracy.
        </div>
        Our 5 meter resolution dataset for 2017 to present is available
        exclusively for non-commercial use. Our dataset at 30 meter resolution
        for 1990 to present will be available in December 2023 for both
        commercial and non-commercial use.
      </ExploreTitleAndDesc>
      <FeatsAndBenefits feats={featsAndBenefits} />
      <Method {...method} />
      <ProductNews product={beTagsFlat.activity} />
    </>
  );
}
