import { Link } from "react-router-dom";
import { MenuToggle } from "../components";
import { routesFlat } from "@routes";
import { logos } from "@helpers/constants";

export function NavHeader({ isOpen, cycleOpen }) {
  return (
    <div className="flex justify-between w-full md:w-[95%] top-0 md:mt-2 mt-4 z-40 absolute">
      <Link to={routesFlat.landing} className={`flex ml-4 ${isOpen ? "md:hidden fixed" : ""}`}>
        <img src={logos.withText} alt="ctrees logo with heatmap colors" className="md:h-[50px] h-[40px] w-auto self-center" />
        {/* <div className="font-bold text-[40px] ml-2">CTrees</div> */}
      </Link>
      <div className={`mr-10 z-60 ${isOpen ? "fixed md:right-14 right-12" : "absolute md:right-0 right-12"}`}>
        <MenuToggle toggle={cycleOpen} isOpen={isOpen} />
      </div>
    </div>
  );
}
