import { width, height } from "../../constants";

export default function addTooltipFunctionality(
    svg,
    lines,
    xScale,
    yScale,
    setTooltip,
    unit,
    lineColors,
) {
    // Add the vertical line that intercepts the data points
    const verticalLine = svg
        .append("line")
        .attr("class", "vertical-line")
        .attr("x1", -1)
        .attr("x2", -1)
        .attr("y1", 0)
        .attr("y2", height)
        .attr("stroke", "transparent") // Set the initial color to transparent
        .attr("stroke-width", 1)
        .style("stroke-dasharray", "3, 3");

    // Add rect, vertical line, and circles at each data point for each line
    lines.forEach((series, seriesIndex) => {
        // Add a hover rect for each data point (year)
        svg.selectAll(`.hover-rect-${seriesIndex}`)
            .data(series.values)
            .enter()
            .append("rect")
            .attr("class", "hover-rect")
            .attr("x", (d) => xScale(d.year) + xScale.bandwidth() / 2 - 10)
            .attr("y", 0)
            .attr("width", 20)
            .attr("height", height)
            .attr("fill", "transparent")
            .on("mouseover", (event, d) => {
                const x = xScale(d.year) + xScale.bandwidth() / 2;
                verticalLine
                    .attr("x1", xScale(d.year) + xScale.bandwidth() / 2)
                    .attr("x2", xScale(d.year) + xScale.bandwidth() / 2)
                    .attr("y1", 0)
                    .attr("y2", height)
                    .attr("stroke", "white"); // Make the line white

                // Change the opacity of all circles on the x-axis on hover
                svg.selectAll(".circle")
                    .filter((circleData) => circleData.year === d.year)
                    .attr("fill-opacity", 1);

                // Set the state of the tooltip
                const tooltipHeight = 100;
                const tooltipX = x < width / 2 + 30 ? x + 20 : x - 140;
                const tooltipY = (height - tooltipHeight) / 2;

                // Update the tooltip position
                setTooltip({
                    visible: true,
                    year: d.year,
                    data: lines.map((line) => {
                        return {
                            name: line.name,
                            value: line.values.find(
                                (point) => point.year === d.year,
                            )[unit],
                            color: lineColors[lines.indexOf(line)],
                        };
                    }),
                    unit: unit,
                    x: tooltipX,
                    y: tooltipY,
                });
            })
            .on("mouseout", (event, d) => {
                verticalLine.attr("stroke", "transparent");

                // Change the opacity of all circles on the x-axis on hover
                svg.selectAll(".circle")
                    .filter((circleData) => circleData.year === d.year)
                    .attr("fill-opacity", 0);
                // Hide the tooltip on mouseout
                setTooltip({
                    visible: false,
                    data: [],
                    x: 0,
                    y: 0,
                });
            });

        // Add transparent circles over each point
        svg.selectAll(`.circle-${seriesIndex}`)
            .data(series.values)
            .enter()
            .append("circle")
            .attr("class", "circle")
            .attr("cx", (d) => xScale(d.year) + xScale.bandwidth() / 2)
            .attr("cy", (d) => yScale(d[unit]))
            .attr("r", 5)
            .attr("fill", lineColors[seriesIndex])
            .attr("fill-opacity", 0); // Set initial opacity
    });
}
