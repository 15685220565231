import React, { useState, useEffect, useRef } from "react";

const SearchInput = ({
  filteredData,
  searchTerm,
  setSearchTerm,
  displayText,
  isDisplayText,
  setIsDisplayText,
  setDisplayText,
  setFilteredData,
  setIsOpen,
  handleKeyDown,
  inputStyle
}) => {
  

  const inputRef = useRef(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  // user typing
  const handleInputChange = (value) => {
    setSearchTerm(value);
    setIsDisplayText(false);
    setFilteredData(filteredData);
    setIsOpen(true);

    // empty search
    if (value.trim() === "") {
      handleResetSearchTerm();
    }
  };

  // user clears input
  const handleInputBlur = () => {
    if (searchTerm.trim() === "" && displayText.trim() === "") {
      handleResetSearchTerm();
    } else {
      setIsDisplayText(true);
    }
  };

  function handleResetSearchTerm() {
    setSearchTerm(""); // Clear search term
    setDisplayText(""); // Clear display text
    setIsDisplayText(false); // Reset to search again
  }

  // start typing or go back to what user was typing before selecting item
  const handleSingleClick = () => {
    setDisplayText(searchTerm);
    setIsDisplayText(false); // will help go back to inputing (original search term)

    // Select all text in the input field
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  // selects all text in the input for user for a new search
  const handleDoubleClick = () => {
    // Select all text in the input field
    if (inputRef.current) {
      inputRef.current.select();
    }
  };

  return (
      <input
        type="text"
        placeholder="Search location..."
        value={isDisplayText ? displayText : searchTerm}
        onChange={(e) => handleInputChange(e.target.value)}
        onClick={handleSingleClick}
        onDoubleClick={handleDoubleClick}
        onBlur={handleInputBlur}
        onKeyDown={handleKeyDown}
        ref={inputRef}
        className={inputStyle}
      />
      
  );
};

export default SearchInput;
