import { height } from "../../constants";

export default function addXAxis(svg, xAxis) {
    return svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0, ${height})`)
        .call(xAxis)
        .selectAll("text")
        .attr("class", "text-black");
}
