/* SIDE PANEL COPY */

export const panelCarbonStock = {
  title: "Carbon Stock",
  desc: "Amount of carbon stored in above-ground and below-ground live vegetation biomass",
  legend: [
    "Total carbon",
    "Carbon in forest areas",
    "Carbon in nonforest areas",
  ],
  titleProgressBars: "Carbon Stock",
  subtitleProgressBars: "Average for selected years",
  progressBars: [
    "Total carbon",
    "Carbon in forest areas",
    "Carbon in nonforest areas",
  ],
  infoTooltip: {
    title: "Carbon Stock Info",
    content: [
      '<p><strong><span style="background-color:#ffffff;font-size:11pt;">Carbon stock&nbsp;</span></strong><span style="background-color:#ffffff;font-size:11pt;">is the amount of carbon stored in the above-ground and below-ground live vegetation biomass in a given area, expressed as carbon density in the unit of tons of carbon dioxide equivalent per hectare (tCO2e/ha).&nbsp;</span></p>' +
        '<p><strong><span style="background-color:#ffffff;font-size:11pt;">Forest carbon</span></strong><span style="background-color:#ffffff;font-size:11pt;">&nbsp;is the amount of carbon stored in forested areas. Forest is defined as an area of one hectare covered by a minimum of 10 percent tree cover with average tree height of 5 m.&nbsp;</span></p>' +
        '<p><strong><span style="background-color:#ffffff;font-size:11pt;">Nonforest carbon&nbsp;</span></strong><span style="background-color:#ffffff;font-size:11pt;">is the amount of carbon stored in all non-forested areas, excluding water and bare land features. Nonforest areas refer to all vegetation types that do not meet the definition of forests, including woodland savanna, treed grasslands, shrublands, linear woody vegetation along roads and streams, tundra, etc.&nbsp;</span></p>',
    ],
  },
};

export const panelEmissions = {
  title: "Emissions",
  desc: "Amount of carbon dioxide released into the atmosphere from loss of above-ground biomass due to landscape-altering activities.",
  legend: ["Total emissions", "Deforestation", "Degradation", "Fire"],
  titleProgressBars: "Emissions breakdown",
  subtitleProgressBars: "Average for selected years",
  progressBars: ["Total emissions", "Deforestation", "Degradation", "Fire"],
  infoTooltip: {
    title: "Emissions Info",
    content: [
      '<p><strong><span style="font-size:11pt;">Emissions</span></strong><span style="font-size:11pt;">&nbsp;are the total amount of carbon dioxide released into the atmosphere from the loss of above-ground biomass due to landscape-altering activities. Emissions are calculated at the time activities occur and are considered committed emissions to the atmosphere.&nbsp;</span></p>' +
        '<p><strong><span style="font-size:11pt;">Deforestation emissions</span></strong><span style="font-size:11pt;">&nbsp;are due to complete clearing of forested areas to other land use or land cover types.&nbsp;</span></p>' +
        '<p><strong><span style="font-size:11pt;">Degradation emissions</span></strong><span style="font-size:11pt;">&nbsp;result from severe alterations to forested areas from logging and road development without changing the land cover or land use from forest to nonforest class.&nbsp;</span></p>' +
        '<p><strong><span style="font-size:11pt;">Fire emissions</span></strong><span style="font-size:11pt;">&nbsp;are due to both natural and anthropogenic burning of vegetation. All emissions from burned areas associated with deforestation (e.g., slash-and-burn forest clearing) are accounted for under deforestation emissions. &nbsp;</span></p>',
    ],
  },
};

export const panelRemovals = {
  title: "Removals",
  desc: "Amount of newly sequestered carbon in plant biomass",
  legend: ["Total", "Forest", "Nonforest"],
  titleProgressBars: "Removals breakdown",
  subtitleProgressBars: "Average for selected years",
  progressBars: ["Total", "Forest", "Nonforest"],
  infoTooltip: {
    title: "Removals Info",
    content: [
      '<p><strong><span style="font-size:11pt;">Carbon removals&nbsp;</span></strong><span style="font-size:11pt;">are the amount of newly sequestered carbon in plant biomass. The total represents carbon removals for all land cover types in a given area. Carbon removals are calculated as residual term using carbon stock change methodology and emissions from land use activities in an area (pixel).&nbsp;</span></p>' +
        '<p><strong><span style="font-size:11pt;">Forest removals</span></strong><span style="font-size:11pt;">&nbsp;are the amount of carbon sequestration in forested areas during a given time period. &nbsp;</span></p>' +
        '<p><strong><span style="font-size:11pt;">Nonforest removals</span></strong><span style="font-size:11pt;">&nbsp;are the amount of carbon sequestration in all non-forested areas, excluding water and bare land features, during a given time period. &nbsp;</span></p>',
    ],
  },
};

export const panelArea = {
  title: "Forest Area",
  desc: "Forest versus nonforest area in hectares",
  legend: ["Forest", "Nonforest"],
  titleProgressBars: "Area breakdown in 2022",
  progressBars: ["Forest", "Nonforest"],
  infoTooltip: {
    title: "Forest Area Info",
    content: [
      '<p><span style="background-color:#ffffff;font-size:11pt;">To estimate forest versus nonforest area, CTrees uses a combination of 30 meter tree cover from Global Land Analysis & Discovery (GLAD) data of the University of Maryland and NASA`s MODIS 250 meter Continuous Vegetation Field (VCF) data (MOD44B) to set the area of forest for each time interval from 2000 to 2022.  We used the forest definition of tree cover of greater than 20% from the satellite data at the minimum mapping unit of 100 m to set the forest area and the area change globally.</span></p>',
    ],
  },
};

/* BUTTON W MODAL */

const biomassInformation = {
  title: "Live Biomass Carbon Density Map Info",
  content: [
    '<p><span style="background-color:#ffffff;font-size:11pt;">The map represents the amount of carbon stored in the above-ground and below-ground live vegetation biomass in a given area, expressed as carbon density in the unit of tons of carbon dioxide equivalent per hectare (tCO2e/ha).</span></p>'+
    '<p><span style="background-color:#ffffff;font-size:11pt;">Land cover data is not available for certain small island states due to the resolution and limitations of input datasets.</span></p>',
  ],
};

const moreInfo = {
  title: "More Info",
  content: [
    '<p><span style="font-size:11pt;">CTrees\' JMRV tool provides jurisdictional level statistics of carbon stocks in live biomass, gross emissions from land use activities, and gross removals for all forest and non-forest types globally from 2001-2022. &nbsp;</span></p>' +
      '<p><span style="font-size:11pt;">Jurisdictional estimates are based on the global live biomass carbon stock change method at 1-km spatial resolution, and activity data including annual estimates of deforestation, degradation, and forest fire at 30 m spatial resolution developed by a combination of CTrees data products and globally validated data products. &nbsp;</span></p>' +
      '<p><span style="font-size:11pt;">CTrees uses the most recent GADM database to define names and boundaries at national and sub-national levels.</span></p>' +
      '<p><span style="font-size:11pt;">Land cover data is not available for certain small island states due to the resolution and limitations of input datasets.&nbsp;</span></p>' +
      '<p><span style="font-size:11pt;">See our </span><span style="font-size:11pt;"><a href="https://ctrees.org/products/jmrv" target="_blank" rel="noopener noreferrer"><u>methodology</u></a></span><span style="font-size:11pt;">&nbsp;</span><span style="font-size:11pt;">for more information.&nbsp;</span></p>'+
      '<p><span style="font-size:11pt;"><i>Data last updated 2023-12-05</i></span></p>'
  ],
};

const advancedMRV = {
  title: "Advanced MRV Features",
  content: [
    '<p><span style="font-size:11pt;">CTrees provides jurisdictions and their partners with geospatial data products and advisory services to support policy planning, greenhouse gas inventories, land use activity monitoring, and carbon credit development. Our advanced data products include:</span></p>' +
    '<ul style="padding-left:16px;">' +
    '<li style="list-style-type:circle;"><span style="font-size:11pt;">Customized maps of carbon stocks, biomass, and activity&nbsp;</span></li>' +
    '<li style="list-style-type:circle"><span style="font-size:11pt;">Emission factors with uncertainty estimates&nbsp;</span></li>' +
    '<li style="list-style-type:circle"><span style="font-size:11pt;">MRV system design&nbsp;</span></li>' +
    '<li style="list-style-type:circle"><span style="font-size:11pt;">Data for carbon programs under standards such as ART/TREES and Verra Jurisdictional Nested REDD+ (JNR).&nbsp;</span></li>' +
    '</ul>' +
    '<p><span style="font-size:11pt;">To explore jurisdiction-level partnerships, please reach out via our </span><span style="font-size:11pt;"><a href="https://ctrees.org/contact" target="_blank" rel="noopener noreferrer"><u>contact form</u></a></span><span style="font-size:11pt;">.&nbsp;</span></p>',
  ],
};

const titleAlerts = "Subscribe";
const descAlerts = [
  `Subscribe to the CTrees mailing list for updates as new data becomes available.`,
];
const alerts = { title: titleAlerts, content: [descAlerts], signUp: true };

export const modalCopy = {
  moreInfo,
  advancedMRV,
  alerts,
  biomassInformation,
};
