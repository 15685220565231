import { axisLeft } from "d3-axis";

const d3 = { axisLeft };

const formatNumber = (num, precision) => {
    return num.toExponential(precision);
};

export default function getTicksOnYAxis(yScale) {
    return d3
        .axisLeft(yScale)
        .ticks(5) // You can adjust the number of ticks as needed
        .tickSize(5)
        .tickFormat((d, i, ticks) => {
            const totalTicks = ticks.length;
            if (i === 0) {
                return 0;
            } else if (
                i === totalTicks - 1 ||
                i === Math.floor(totalTicks / 2)
            ) {
                return Math.abs(d) > 1000 ? formatNumber(d) : d;
            } else {
                return "";
            }
        });
}
