import { useEffect } from "react";
import { Helmet } from "react-helmet";
import { Products, Partners, Latest, UseCases } from "./components";
import { ScrollProgress, MetaTagHeader } from "../../components";
import { Hero } from "./components/Tree.tsx";

const metaProps = {
  title: "CTrees: Track forest carbon anywhere on the planet",
  desc: "Get accurate information on demand with the only global forest carbon and activity data platform.",
  img: ''
}

export default function Landing() {
  useEffect(() => {
    new Hero(document.querySelector('[data-component="Hero"]'));
  }, [this]);


  return (
    <>
      <MetaTagHeader {...metaProps} />
      <div className="w-full flex flex-col justify-center items-center">
        <ScrollProgress />
        <div className="w-full h-navscreen flex relative z-80 mb-20 justify-center items-center">
          {/* ANIMATED TREE */}
          <div
            className="background background--hero -mt-[100px] h-[400px] md:h-[700px] w-screen md:-mt-[8rem]"
            data-component="Hero"
            data-particle="https://assets.huncwot.net/ctrees/particle3.png"
          >
            <canvas className="background__canvas h-screen w-screen"></canvas>
          </div>
          <div className="js-progress"></div>
          {/* SUB HEADER TEXT */}
          <div className="z-30 self-center bottom-0 -mb-14 absolute">
            <div className={`w-full my-0 md:mx-0 flex flex-col`}>
              <div
                className={`bg-white/25 flex flex-col px-10 md:px-0 items-center`}
              >
                <div
                  className={`font-black text-3xl md:text-[50px] max-w-[400px] md:max-w-[600px] leading-none text-center`}
                >
                  Track forest carbon anywhere on the planet
                </div>
                <div
                  className={`md:text-2xl text-xl opacity-1 leading-tight mt-4 mb-8 max-w-[450px] text-center`}
                >
                  Get accurate information on demand with the only global forest
                  carbon and activity data platform.
                </div>
              </div>
            </div>
          </div>
        </div>

        <Products />
        <UseCases />
        <Latest />
        <Partners />
      </div>
    </>
  );
}

export const fullWidthSubHeader =
  "py-12 flex justify-center font-extrabold border-y-[1px] border-black/[10%] md:w-overlines bg-white";
