import React, { useState } from "react";
import Slider from "rc-slider";
import "rc-slider/assets/index.css";

const MultiRangeSlider = ({ id, onFilterChange, min, max }) => {
  const [range, setRange] = useState([min, max]);

  const handleSliderChange = (newRange) => {
    setRange(newRange);
    onFilterChange(id, newRange);
  };

  function getOverlapOffset(index) {
    // if they have selected two years, the labels will need to adjust
    if (Math.abs(range[1] - range[0]) === 1) {
      if (range[0] === min) {
        // only adjust the max range label if the min range is far left
        if (index === 0) {
          return 18;
        } else {
          return -15;
        }
      } else if (range[1] === max) {
        if (index === 0) {
          return 15;
        } else {
          return -15;
        }
      } else {
        if (index === 0) {
          return 20;
        } else {
          return -15;
        }
      }
    } else if (Math.abs(range[1] - range[0]) === 2) {
      if (index === 0) {
        return 10;
      } else {
        return -10;
      }
    } else if (Math.abs(range[1] - range[0]) === 3) {
      if (index === 0) {
        return 8;
      } else {
        return -2;
      }
    } else {
      return 0;
    }
  }

  const handleStyle = {
    backgroundColor: "white",
    opacity: "1",
    width: "24px",
    height: "24px",
    borderRadius: "9999px",
    border: "black solid 2px",
    top: "20%",
  };

  return (
    <div className="w-5/6 h-10 mx-auto my-4 relative">
      <Slider
        min={min}
        max={max}
        range
        step={1}
        defaultValue={range}
        onChange={handleSliderChange}
        trackStyle={[{ backgroundColor: "black", height: "12px" }]}
        handleStyle={[handleStyle, handleStyle]}
      />
      {range.map((r, index) => (
        <div
          key={index}
          className="pt-2.5 absolute"
          style={{
            left: `calc(${
              ((r - min) / (max - min)) * 100 - 6
            }% - ${getOverlapOffset(index)}px)`,
          }}
        >
          <div className="px-2 py-1 bg-black rounded-lg inline-flex">
            <div className="opacity-90 text-white text-xs">{r}</div>
          </div>
        </div>
      ))}
    </div>
  );
};

export default MultiRangeSlider;
