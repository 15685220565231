import React, { useState } from "react";
import UpArrow from '@assets/up-arrow-white.svg';
import DownArrow from "@assets/down-arrow-white.svg";

export default function PanelContainer({ children }) {
  const [isDrawerOpen, setIsPanelOpen] = useState(false);

  const toggleDrawer = () => {
    setIsPanelOpen(!isDrawerOpen);
  };

  const panel = `hidden lg:block lg:top-0 lg:left-0 lg:absolute lg:h-auto`;
  return (
    <>
      <div className={`${panel}`}>{children}</div>
      <div
        className={`lg:hidden absolute w-full bg-white bottom-0 left-0 inline-block z-40 overflow-hidden transition-all duration-300 ease-in-out ${
          isDrawerOpen ? "h-[88%]" : "h-auto"
        }`}
      >
        <input
          type="checkbox"
          id="drawer-toggle"
          className="hidden relative sr-only"
          defaultChecked={isDrawerOpen}
        />
        <div
          className={`lg:hidden w-full inline-flex justify-between p-4 bg-black text-white text-xl font-semibold`}
          onClick={toggleDrawer}
        >
          <div>Metrics</div>{" "}
          {isDrawerOpen ? (
            <img src={DownArrow} alt="open subsection" />
          ) : (
            <img src={UpArrow} alt="closed subsection" />
          )}
        </div>
        <div
          className={`lg:hidden fixed w-full h-5/6 pb-56 overflow-y-auto transition-all duration-300 bg-white ${
            isDrawerOpen ? "translate-y-0" : "h-0 translate-y-full"
          }`}
        >
          <div className="lg:hidden bg-white">{children}</div>
        </div>
      </div>
    </>
  );
}
