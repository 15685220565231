export default function DateImgURI({ uri, onUri, date, onDate, imgURL, onImgURL }) {
  return (
    <>
      <label className={labelStyle}>URI</label>
      <input
        required
        className={inputStyle}
        value={"/" + uri}
        onChange={onUri}
      />
      <label className={labelStyle}>Date</label>
      <input
        className={inputStyle}
        type="date"
        value={date}
        onChange={onDate}
      />
      <label className={labelStyle}>Image</label>
      <div className="flex items-center">
        <div className="text-sm w-[50px]">URL</div>
        <input className={inputStyle} value={imgURL} onChange={onImgURL} />
      </div>
    </>
  );
}

const labelStyle =
  "first:mt-0 font-bold mt-4 bg-lightgray/50 px-2 rounded-t-md";
const inputStyle =
  "p-2 w-full h-10 border-b-2 text-sm focus:outline-none cursor-pointer";
