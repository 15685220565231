import { tagsList } from "@/helpers/constants";

export default function Tags({ onTags, tags }) {
  return (
    <>
      <label className={labelStyle}>Tags</label>
      <div className="flex flex-wrap flex-col mt-2 mb-20">
        <div className="flex flex-wrap">
          {tagsList.map((tag) => {
            const selected = tags.includes(tag.be);

            return (
              <div
                className={tagStyle + (selected ? " bg-green" : " bg-offWhite")}
                onClick={() => onTags(tag)}
              >
                {tag.display}
              </div>
            );
          })}
        </div>
      </div>
    </>
  );
}

const tagStyle = "text-sm mb-4 cursor-pointer p-2 w-[45%] odd:mr-4";
const labelStyle =
  "first:mt-0 font-bold mt-4 bg-lightgray/50 px-2 rounded-t-md";
