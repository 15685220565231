import React, { createContext, useState, useEffect } from "react";
import ReactDOM from "react-dom/client";
import ReactGA from "react-ga4";
import { BrowserRouter, useLocation } from "react-router-dom";
import Routes, { routesFlat } from "@routes";
import { NavMenu, Footer, StayConnected } from "./components";
import reportWebVitals from "./reportWebVitals";
import { ScrollToTop } from "@helpers";
import { newsFilterOptions } from "@helpers/constants";
import "./index.css";
import "./pages/products/pages/JMRV/Map/map.css";
import "react-loading-skeleton/dist/skeleton.css";

ReactGA.initialize("G-KTSEKJ0CT0");

export const CMSContext = createContext(null);
export const NewsContext = createContext(null);

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Root />
  </BrowserRouter>
);

function Root() {
  const [state, setState] = useState(undefined);
  const [articles, setArticles] = useState([]);
  const [newsFilter, setNewsFilter] = useState({
    product: newsFilterOptions.product.none,
    type: newsFilterOptions.type.none,
  });

  useEffect(() => {
    fetch("/api/v1/newsfeed/summary")
      .then((resp) => resp.json())
      .then((arr) => {
        if (arr.length) {
          setArticles(arr);
        }
      });
  }, []);

  const location = useLocation();
  const path = location.pathname;

  const privacyOrTerms =
    path === routesFlat.privacy || path === routesFlat.terms;
  const contactPage = path === routesFlat.contact;

  const notLuca =
    path !== routesFlat.lucaMap && path !== routesFlat.directLucaMap;
  const notJmrv =
    path !== routesFlat.jmrvMap && path !== routesFlat.directJMRVMap;

  const displayFooter = notLuca && notJmrv;
  const displayConnected = displayFooter && !privacyOrTerms && !contactPage;

  const desktop = "md:mx-4 md:border-black/[10%] md:border-x-[1px]";

  return (
    <CMSContext.Provider value={{ state, setState }}>
      <div className="p-0 w-full h-full font-body">
        <div className={desktop}>
          <ScrollToTop />
          <NavMenu />
          <NewsContext.Provider value={{ articles, newsFilter, setNewsFilter }}>
            <Routes />
          </NewsContext.Provider>
          {displayConnected && <StayConnected />}
          {displayFooter && <Footer />}
        </div>
      </div>
    </CMSContext.Provider>
  );
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
