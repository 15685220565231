import { select } from "d3-selection";
import { line } from "d3-shape";

const d3 = {
    select,
    line,
};

export default function generateLines(svg, multiLineData, xScale, yScale, numXAxisValues, unit, lineColors) {
    return multiLineData.forEach((series, seriesIndex) => {
        const lineGenerator = d3
            .line()
            .x((d) => xScale(d.year) + xScale.bandwidth() / 2)
            .y((d) => yScale(d[unit]));

        // Check if there is only one year in the data
        if (numXAxisValues === 1) {
            multiLineData.forEach((series, seriesIndex) => {
                series.values.forEach((dataPoint) => {
                    // Calculate the x and y coordinates for the horizontal line
                    const x1 = xScale(dataPoint.year);
                    const x2 = xScale(dataPoint.year) + xScale.bandwidth();
                    const y = yScale(dataPoint[unit]);

                    // Append a line element to the chart
                    svg.append("line")
                        .attr("x1", x1)
                        .attr("x2", x2)
                        .attr("y1", y)
                        .attr("y2", y)
                        .attr("stroke", lineColors[seriesIndex])
                        .attr("stroke-width", 2);
                });
            });
        } else {
            // Multiple years selected
            svg.append("path")
                .datum(series.values)
                .attr("d", lineGenerator)
                .attr("stroke", lineColors[seriesIndex])
                .attr("stroke-width", 2)
                .attr("fill", "none")
                .transition()
                .duration(1000)
                .attr("d", lineGenerator);
        }
    });
}
