import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { formatDateMonth } from "@helpers";
import { PageHeader } from "@components";
import { wrapImagesWithDiv } from "./helpers/wrapImagesWithDiv";
import { Latest } from "../../landing/components";
import "./article.css";

export default function Article() {
  const { id } = useParams();
  const [article, setArticle] = useState([]);
  const splitUri = id.split("-");
  const articleId = splitUri[splitUri.length - 1];
  const date = article?.publish_date
    ? formatDateMonth(article?.publish_date)
    : "";

  useEffect(() => {
    fetch(`/api/v1/newsfeed/item?id=${articleId}`)
      .then((resp) => resp.json())
      .then((item) => {
        const body = wrapImagesWithDiv(item.body);
        const newItem = { ...item, body };
        setArticle(newItem);
      });
  }, [articleId]);

  const pageHeader = {
    title: article.title,
    tags: { products: article?.product_tags, type: article?.article_tags },
    desc: date,
    img:
      article.image_url ||
      "https://ctrees-website.s3.us-west-2.amazonaws.com/website_images/static/heatmap/4.png",
    alt: article.image_credits,
    article: true,
  };
  return (
    <div className="w-full flex flex-col justify-center items-center">
      <PageHeader {...pageHeader} />
      <div className="md:mt-0 -mt-20 flex flex-col justify-center items-center">
        {article?.body ? (
          <div
            className="md:w-1/2 px-4 text-lg md:text-md flex flex-col mb-20 article-body"
            dangerouslySetInnerHTML={{ __html: article.body }}
          ></div>
        ) : (
          <div className="my-10">
            {Array(12)
              .fill(null)
              .map((i) => (
                <Skeleton className="md:max-w-1/2 w-[300px] md:w-[800px] h-[20px]"/>
              ))}
            <Skeleton className="md:max-w-1/2 w-[150px] md:w-[400px] h-[20px]"/>
          </div>
        )}
      </div>
      <Latest />
    </div>
  );
}
