import { width, height, margin } from "../../constants";

export default function addNegativeBackground(svg, yScale) {
    // Append a grey background rectangle for negative values
    return svg
        .append("rect")
        .attr("x", margin.left)
        .attr("y", yScale(0)) // Set the y-coordinate based on the y-axis scale
        .attr("width", width)
        .attr("height", height - yScale(0)) // Set the height based on the y-axis scale
        .attr("fill", "#36373A");
}
