import { width, margin } from "../../constants";
import { scaleBand } from "d3-scale";

const d3 = { scaleBand };

export default function getXScale(values) {
    return d3
        .scaleBand()
        .domain(values)
        .range([margin.left, width + margin.left])
        .padding(0.1);
}
