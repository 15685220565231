import React from "react";

const ChildItem = ({
  id,
  data,
  handleSelectItem,
  handleKeyDown,
  displayText,
  label,
  textStyle,
}) => {
  const childStyle = `ml-4 cursor-pointer`;
  return (
    <ul id={id} role="list" aria-label={label}>
      {data.children.map((item) => (
        <li key={item.id} className={childStyle} tabIndex={0}>
          <div
            className={textStyle}
            data-displaytext={`${item.name}, ${displayText}`}
            tabIndex={1}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(
                `${item.name}, ${displayText}`,
                item.admin,
                item.id
              );
            }}
            onKeyDown={(e) => {
              handleKeyDown(
                e,
                `${item.name}, ${displayText}`,
                item.admin,
                item.id
              );
            }}
            aria-label={item.name}
            role="button"
          >
            {item.name}
          </div>
        </li>
      ))}
    </ul>
  );
};

export default ChildItem;
