import React from "react";

const Legend = ({ legendItems }) => {
  return (
    <div className="w-3/4 pt-3 gap-3 flex flex-wrap m-4 ml-16">
      {Object.keys(legendItems).map((key) => (
        <div key={key} className="flex items-center gap-2">
          <div
            style={{ backgroundColor: legendItems[key].color }}
            className="w-3 h-3"
          />
          <div className="opacity-90 text-black text-sm leading-tight">
            {legendItems[key].display}
          </div>
        </div>
      ))}
    </div>
  );
};

export default Legend;
