import { margin, width } from "../../constants";

export default function addChartBackground(svg, yScale) {
    // Append a black background rectangle
    return svg.append("rect")
        .attr("x", margin.left)
        .attr("y", 0)
        .attr("width", width)
        .attr("height", yScale(0)) // Set the height based on the y-axis scale
        .attr("fill", "#000000");
}
