import { useState, useContext } from "react";
import { CMSContext } from "@";
import { logInUser } from "../helpers";

export default function Auth() {
  const { state } = useContext(CMSContext);
  const [error, throwError] = useState('');
  const [email, onEmailChange] = useState(undefined);
  const [password, onPassChange] = useState(undefined);

  const onUser = (e) => {
    throwError(false);
    onEmailChange(e.target.value);
  };

  const onPass = (e) => {
    throwError(false);
    onPassChange(e.target.value);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
  
    logInUser(email, password)
      .then((resp) => {
        localStorage.setItem("token", resp.data.token);
        state.setToken(resp.data.token);
      })
      .catch((err) => throwError(err));
  };

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="flex flex-col">
        <div className="text-center text-4xl font-bold mb-10 -mt-20">
          Admin Login
        </div>
        <form className="flex flex-col" onSubmit={handleSubmit}>
          <label>email</label>
          <input
            className={inputStyle}
            type="email"
            value={email}
            onChange={onUser}
          />
          <label>Password</label>
          <input
            className={inputStyle}
            type="password"
            value={password}
            onChange={onPass}
          />
          <div className={errorStyle}>{error}</div>
          <button className={submitBtn}>submit</button>
        </form>
      </div>
    </div>
  );
}

const errorStyle = "my-2 h-4 font-bold text-xs text-[red]";
const inputStyle = "px-2 w-[300px] h-10 rounded-md border-2";
const submitBtn = "h-10 w-full bg-black text-white font-bold rounded-md";
