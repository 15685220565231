import { otherLineColors, orderItems } from "../../constants";
import { scaleOrdinal } from "d3-scale";
import { stack } from "d3-shape";
import { select } from "d3-selection";
import { transition } from "d3-transition";

const d3 = { select, stack, scaleOrdinal, transition };

export default function generateBars(
    svg,
    data,
    xScale,
    yScale,
    unit,
    setTooltip,
) {
    const stack = d3
        .stack()
        .keys(orderItems.area.slice(1))
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone);

    const stackedData = stack(data);

    const tooltipStack = d3
        .stack()
        .keys(orderItems.area)
        .order(d3.stackOrderNone)
        .offset(d3.stackOffsetNone);

    const tooltipStackedData = tooltipStack(data);

    const colorScale = d3
        .scaleOrdinal()
        .domain(orderItems.area)
        .range(otherLineColors);

    // Append a group for the stacked bars
    const groupStackedBars = svg.append("g");

    groupStackedBars
        .selectAll("g")
        .data(stackedData)
        .enter()
        .append("g")
        .attr("fill", (d) => colorScale(d.key))
        .attr("data-stack", (d) => d.key)
        .on("click", (event, d) => {
            // this event will be used to filter the data, tbd
            const clickedCategory = d.key;
        })
        .selectAll("rect")
        .data((d) => d)
        .enter()
        .append("rect")
        .attr("x", (d) => xScale(d.data.year))
        .attr("y", (d) => yScale(d[1]))
        .attr("height", (d) => yScale(d[0]) - yScale(d[1]))
        .attr("width", xScale.bandwidth())
        .on("mouseover", (event, d) => {
            // tooltip mouse event
            const year = d.data.year;
            const left = year > 2010 ? -140 : 35;
            const x = xScale(d.data.year) + xScale.bandwidth() / 2 + left;
            const y = 90;
            const displayData = {};

            tooltipStackedData.forEach((element) => {
                element.forEach((item) => {
                    if (item.data.year === year) {
                        displayData[element.key] = item.data[element.key];
                    }
                });
            });

            const tooltipData = tooltipStackedData.map((stack) => ({
                name: stack.key,
                value: displayData[stack.key],
                color: colorScale(stack.key),
            }));

            setTooltip({
                visible: true,
                year,
                unit: unit,
                x,
                y,
                data: tooltipData,
            });
        })
        .on("mouseout", () => {
            // Hide the tooltip
            setTooltip({ visible: false, x: 0, y: 0, data: [] });
        });

    return;
}
