import React from "react";

const MapTitle = ({
  children, title
}) => {
  return (
    <div className="h-8 p-3 my-4 bg-white rounded-lg justify-center inline-flex">
      <div className="justify-between items-center inline-flex">
        <div className="text-black text-sm">
          {title}
        </div>
        {children}
      </div>
    </div>
  );
};

export default MapTitle;
