import { useState } from "react";
import Mapbox from "./components/Mapbox";
import SearchContainer from "./components/Search/Container";
import ModalComponent from "./components/Modal";
import ButtonComponent from "./components/Modal/components/Button";
import PanelContainer from "./components/SidePanel";
import Panel from "./components/SidePanel/Panel";
import MapTitle from "./components/MapTitle";
import GradientLegend from "./components/GradientLegend";
import { modalCopy } from "./constants";
import advanced from "@assets/advanced.svg";
import alerts from "@assets/alert.svg";
import ctreesLogo from "@assets/ctrees-sm-logo.png";
import { initialChartUnits } from "./components/SidePanel/Panel/components/Charts/constants";
import { getAllChartRanges } from "./components/SidePanel/Panel/components/Charts/helpers";
import { getUnits } from "./components/SidePanel/Panel/helpers";
import clsx from "clsx";

export default function JMRVMap() {
    const [chartData, setChartData] = useState(null);
    const [percentData, setPercentData] = useState(null); // this will change with the multirange slider
    const [units, setUnits] = useState(initialChartUnits);
    const [xAxisLimits, setXAxisLimits] = useState({
        area: [2000, 2022],
        carbon_stock: [2000, 2022],
        emission: [2000, 2022],
        removal: [2000, 2022],
    });
    const [chartRange, setChartRange] = useState({
        area: [],
        carbon_stock: [],
        emission: [],
        removal: [],
    });
    const [modalIsOpen, setModalIsOpen] = useState(false);
    const [modalContent, setModalContent] = useState(modalCopy.moreInfo);
    const [userInteraction, setUserInteraction] = useState(null); // will be ["click", "search"] based on user interaction
    const [selectedGID, setSelectedGID] = useState("USA"); // default
    const [selectedAdmin, setSelectedAdmin] = useState(0); // default
    const [displayText, setDisplayText] = useState("United States"); // default text on input

    const setSelection = (displayText, gid, adminLevel, userInteraction) => {
        setSelectedGID(gid);
        setSelectedAdmin(adminLevel);
        setUserInteraction(userInteraction);
        setDisplayText(displayText);
    };

    const updateData = (newData, percentData) => {
        if (newData) {
            const ranges = getAllChartRanges(chartRange, newData);
            setChartRange({ ...ranges }); // Update the range in the parent component

            setUnits(getUnits(newData, xAxisLimits));
        }
        setChartData(newData);

        if (percentData) {
            setPercentData(percentData);
        }
    };

    const openModal = (event, modalContent) => {
        event.stopPropagation(); // prevents triggering expandable subsection
        setModalContent(modalContent);
        setModalIsOpen(true);
    };

    const closeModal = () => {
        setModalIsOpen(false);
    };

    const container = clsx(
        `fixed left-0 top-0 z-40 h-screen w-full bg-black lg:relative lg:mt-[80px] lg:h-navscreen`,
    );
    const childContainer =
        "fixed w-full left-0 top-0 lg:relative h-[90vh] lg:h-full";
    const mainContainer =
        "w-auto m-auto lg:m-0 lg:ml-auto lg:relative mb-4 py-4 lg:left-0 lg:w-3/5";

    return (
        <div className={container}>
            <div className={childContainer}>
                <Mapbox
                    updateData={updateData}
                    setSelection={setSelection}
                    selectedGID={selectedGID}
                    selectedAdmin={selectedAdmin}
                    userInteraction={userInteraction}
                />

                <div className="flex">
                    <PanelContainer>
                        <Panel
                            data={chartData}
                            percentData={percentData}
                            setPercentData={setPercentData}
                            units={units}
                            setUnits={setUnits}
                            xAxisLimits={xAxisLimits}
                            initialChartRange={chartRange}
                            setXAxisLimits={setXAxisLimits}
                            updateData={updateData}
                            openModal={openModal}
                            gid={selectedGID}
                            adminLevel={selectedAdmin}
                        />
                    </PanelContainer>
                    <div className={mainContainer}>
                        <div className="fit-content m-auto flex justify-end">
                            <div className="flex flex-col items-center justify-center">
                                <SearchContainer
                                    setSelection={setSelection}
                                    displayText={displayText}
                                    setDisplayText={setDisplayText}
                                />

                                <div className={"z-30 w-fit"}>
                                    <MapTitle
                                        title={
                                            "Live Biomass Carbon Density Map"
                                        }
                                    >
                                        <ButtonComponent
                                            openModal={openModal}
                                            modalContent={
                                                modalCopy.biomassInformation
                                            }
                                            icon={"i"}
                                        />
                                    </MapTitle>
                                </div>
                            </div>

                            <div
                                className={`z-30 ml-auto hidden h-fit w-fit lg:flex`}
                            >
                                <ButtonComponent
                                    openModal={openModal}
                                    modalContent={modalCopy.advancedMRV}
                                    text={"Advanced Features"}
                                    svgIcon={advanced}
                                    backgroundColor={"#DFF841"}
                                />
                            </div>
                        </div>
                        <div
                            className={`absolute left-0 z-30 ml-4 flex w-8 flex-col gap-4 lg:hidden`}
                        >
                            <ButtonComponent
                                openModal={openModal}
                                modalContent={modalCopy.advancedMRV}
                                text={"Advanced Features"}
                                svgIcon={advanced}
                                backgroundColor={"#DFF841"}
                            />
                            <GradientLegend text={">900 Tons CO2e/ha"} />
                        </div>
                        <div className="absolute right-0 mr-2 hidden w-8 lg:block">
                            <GradientLegend text={">900 Tons CO2e/ha"} />
                        </div>
                        <div className="absolute right-0 mr-4 mt-2 grid gap-1.5 lg:fixed lg:bottom-6 lg:right-6 lg:flex lg:gap-8">
                            <ButtonComponent
                                openModal={openModal}
                                modalContent={modalCopy.moreInfo}
                                text={"More Info"}
                                icon={"?"}
                            />

                            <ButtonComponent
                                openModal={openModal}
                                modalContent={modalCopy.alerts}
                                text={"Subscribe"}
                                svgIcon={alerts}
                            />
                        </div>
                        <a
                            className="absolute bottom-16 right-0 mr-1 w-[130px] cursor-pointer lg:bottom-6 lg:hidden"
                            href="https://ctrees.org"
                            target="_blank"
                        >
                            <img
                                href="https://ctrees.org"
                                target="_blank"
                                src={ctreesLogo}
                            ></img>
                        </a>
                    </div>
                    <ModalComponent
                        isOpen={modalIsOpen}
                        onRequestClose={closeModal}
                        modalContent={modalContent}
                    />
                </div>
            </div>
        </div>
    );
}
