const token = localStorage.getItem("token");

export function logInUser(email, password) {

  return fetch(`/cms/v1/admin/login`, {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({ email, password }),
  })
    .then((resp) => resp.json())
}

export function editArticle(id, body) {
  return fetch(`/cms/v1/newsfeed/item?id=${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}` },
    body: JSON.stringify(body),
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function getArticle(id) {
  return fetch(`/cms/v1/newsfeed/item?id=${id}`, {
    method: "GET",
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`  }})
    .then((resp) => resp.json())
    .then((resp) => resp);
}

export function postArticle(body) {
  return fetch("/cms/v1/newsfeed/item", {
    method: "POST",
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`  },
    body: JSON.stringify(body),
  })
    .then((resp) => resp.json())
    .catch((err) => err.message);
}

export function updateArticleState(id, state) {
  return fetch(`/cms/v1/newsfeed/item?id=${id}`, {
    method: "PATCH",
    headers: { "Content-Type": "application/json", "Authorization": `Bearer ${token}`  },
    body: JSON.stringify({ state }),
  })
    .then((resp) => resp.json())
    .then((resp) => resp);
}
