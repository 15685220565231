import React, { useEffect } from "react";
import Subscribe from "./components/Subscribe";
import clsx from "clsx";

const ModalComponent = ({ modalContent, isOpen, onRequestClose }) => {
    useEffect(() => {
        const handleKeyDown = (event) => {
            if (event.key === 'Escape') {
                onRequestClose();
            }
        };

        if (isOpen) {
            document.addEventListener('keydown', handleKeyDown);
        } else {
            document.removeEventListener('keydown', handleKeyDown);
        }

        return () => {
            document.removeEventListener('keydown', handleKeyDown);
        };
    }, [isOpen, onRequestClose]);

    return (
        <>
            {isOpen && (
                <div className={modalAndOverlayContainer}>
                    <div
                        className="fixed inset-0 bg-black opacity-50"
                        onClick={onRequestClose}
                    ></div>
                    <div className={modalContainer}>
                        <div className={modalTitleContainer}>
                            <div className="text-xl font-semibold text-black">
                                {modalContent.title}
                            </div>
                            <button alt="Close button" onClick={onRequestClose}>
                                <span className="sr-only">Close</span>X
                            </button>
                        </div>
                        <div className={modalContentContainer}>
                            {modalContent.content.map((item, index) => {
                                return (
                                    <div
                                        key={index}
                                        className="space-y-4 bg-white px-8 pt-8"
                                        dangerouslySetInnerHTML={{
                                            __html: item,
                                        }}
                                    />
                                );
                            })}
                            {modalContent.signUp && <Subscribe />}
                        </div>
                    </div>
                </div>
            )}
        </>
    );
};

export default ModalComponent;

const modalAndOverlayContainer = clsx(
    "fixed inset-0 z-50 flex items-center justify-center",
);
const modalContainer = clsx(
    "z-10 h-full rounded-lg bg-white p-4 pb-8 shadow-lg md:h-auto md:max-h-2/3 md:w-1/2",
);
const modalTitleContainer = clsx(
    "flex items-center justify-between border-b border-slate-300 bg-white p-8",
);
const modalContentContainer = clsx(
    "flex h-5/6 w-full flex-col overflow-y-auto",
);
