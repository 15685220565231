import { select } from "d3-selection";
import { axisLeft } from "d3-axis";

const d3 = { select, axisLeft };

export default function getTicksOnYAxis(yScale) {
    // Modify the y-axis to show labels only on the grid lines
    return d3
        .axisLeft(yScale)
        .ticks(5)
        .tickSize(5)
        .tickFormat((d, i, ticks) => {
            const totalTicks = ticks.length;
            if (
                i === 0 ||
                i === totalTicks - 1 ||
                i === Math.floor(totalTicks / 2)
            ) {
                return d;
            } else {
                return "";
            }
        });
}
