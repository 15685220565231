import React, { useEffect, useState, useRef } from "react";
import { select } from "d3-selection";
import { extent } from "d3-array";
import { axisBottom } from "d3-axis";
import { width, height, margin } from "../constants";
import { Tooltip } from "../components";
import {
    getTooltipInitiation,
    initiateChart,
    addUnitLabelOnYAxis,
} from "../helpers";
import {
    addLabelsToXAxis,
    cleanTicksOnXAxis,
    addPositiveBackground,
    addNegativeBackground,
    getTicksOnYAxis,
    addLabelsToYAxis,
    addGridlinesToYAxis,
    addTooltipFunctionality,
    generateLines,
    getYScale,
    getXScale,
} from "./helpers";

const d3 = {
    select,
    extent,
    axisBottom,
};

const LineChart = ({ id, multiLineData, unit, xAxisLimits, lineColors }) => {
    const chartContainerRef = useRef(null);
    const [tooltip, setTooltip] = useState(getTooltipInitiation(unit));

    useEffect(() => {
        if (d3.select(chartContainerRef.current)) {
            // Remove all child elements within the SVG or chart container
            d3.select(chartContainerRef.current).selectAll("*").remove();
        }

        /* INITIALIZE CHART */
        const svg = initiateChart(chartContainerRef, width, height, margin);

        /* X-AXIS and SCALES */
        const valuesForCalculations = multiLineData[0].values; // access first item's data to use to calculate axis'
        const xScale = getXScale(valuesForCalculations);

        const xAxis = d3.axisBottom(xScale);
        const numXAxisValues = valuesForCalculations.length;

        // Add first and last label
        addLabelsToXAxis(svg, xAxis, valuesForCalculations.length);

        // Remove ticks from the x-axis except for the first and last
        cleanTicksOnXAxis(svg, valuesForCalculations.length);

        /* Y-AXIS and SCALES */
        const yExtent = d3.extent(
            [].concat(
                ...multiLineData.map((series) =>
                    series.values.map((d) => d[unit]),
                ),
            ),
        );
        const minYValue = yExtent[0];
        const maxYValue = yExtent[1];
        const padding = maxYValue === 0 ? 1 : maxYValue < 200 ? 20 : 50;

        const yScale = getYScale(minYValue, maxYValue, padding);

        // Append background before adding yaxis gridlines
        // Append a black background rectangle for positive values
        addPositiveBackground(svg, yScale);
        // Append a grey background rectangle for negative values
        addNegativeBackground(svg, yScale);

        // Modify the y-axis to show labels only on the grid lines
        const yAxis = getTicksOnYAxis(yScale);
        addLabelsToYAxis(svg, yAxis);
        addUnitLabelOnYAxis(svg, unit);
        addGridlinesToYAxis(svg, yScale);

        /* GENERATE LINES */
        generateLines(
            svg,
            multiLineData,
            xScale,
            yScale,
            numXAxisValues,
            unit,
            lineColors,
        );

        /* CHART ACCESSORIES */
        addTooltipFunctionality(
            svg,
            multiLineData,
            xScale,
            yScale,
            setTooltip,
            unit,
            lineColors,
        );
    }, [multiLineData, xAxisLimits]);

    return (
        <>
            <Tooltip {...tooltip} colors={lineColors} />
            <div
                ref={chartContainerRef}
                id={id}
                className="panelChart flex items-center justify-center"
            ></div>
        </>
    );
};

export default LineChart;
