export default function SumALT({ imgURL, imgALT, onAlt, summary, onSummary }) {
  return (
    <>
      <div className="flex items-center">
        <div className="text-sm w-[50px]">ALT</div>
        <input className={inputStyle} value={imgALT} onChange={onAlt} />
      </div>
      <img
        src={imgURL}
        className="h-[100px] border-2 border-lightgray/50 rounded-md mt-4"
      />
      <label className={labelStyle}>Summary</label>
      <textarea
        className={
          "border-2 p-2 text-sm rounded-md focus:outline-none w-full mt-2"
        }
        type="summary"
        value={summary}
        placeholder={summary || "Write a summary about the article here"}
        onChange={onSummary}
      />
    </>
  );
}

const labelStyle =
  "first:mt-0 font-bold mt-4 bg-lightgray/50 px-2 rounded-t-md";
const inputStyle =
  "p-2 w-full h-10 border-b-2 text-sm focus:outline-none cursor-pointer";