import { width, margin } from "../../constants";
import getTicksOnYAxis from "./getTicksOnYAxis";

export default function addGridlinesToYAxis(svg, yScale) {
    // Append the gridlines to the chart
    svg.append("g")
        .attr("class", "grid")
        .attr("transform", `translate(${margin.left}, 0)`)
        .call(getTicksOnYAxis(yScale).tickSize(-width).tickFormat(""));

    // Apply styles to the gridlines
    svg.selectAll(".grid line")
        .attr("stroke", "gray")
        .attr("stroke-opacity", "0.7");

    return;
}
