import { areaUnitOrder, otherUnitOrder, initialChartUnits } from "../components/Charts/constants";
import isNumberInRange from "./isNumberInRange";

const formatAreaValue = (value) => {
    /**
     * Format area value (Ha) based on magnitude:
     * - If value is greater than 1,000,000, display in million hectares (Ha).
     * - If value is less than 0.01, display in square meters (m2).
     * - Otherwise, display in hectares (Ha).
     */
    if (value > 1000000) {
        return "MHa";
    } else if (value < 0.01) {
        return "m2";
    } else {
        return "Ha";
    }
}

const formatCO2eValue = (MtCO2eValue, values) => {
    /**
     * Format CO2e value based on magnitude (input is tera grams (MtCO2e))
     * - If value is between .1 MtCO2e and 100 MtCO2e, display in MtCO2e.
     * - If value is higher than 100 MtCO2e, display in GtCO2e.
     * - If value is between 0.1 KtCO2e and 100 KtCO2e, display in KtCO2e.
     * - If value is smaller than 0.1 KtCO2e, display in tCO2e.
     */
    if (Math.abs(MtCO2eValue) >= 100 && Math.abs(values["GtCO2e"]) > 1) {
        return "GtCO2e";
    } else if (
        1e-4 <= Math.abs(MtCO2eValue) &&
        Math.abs(MtCO2eValue) < 1 &&
        Math.abs(values["KtCO2e"]) > 1
    ) {
        return "KtCO2e";
    } else if (Math.abs(MtCO2eValue) < 1e-4) {
        return "tCO2e";
    } else {
        return "MtCO2e";
    }
}


export default function getUnits(data, range) {
    let tempUnits = initialChartUnits;
    Object.keys(data).map((value, index) => {
        if (value === "area") {
            data[value].forEach((v) => {
                v.values.forEach((val) => {
                    const areaValue = formatAreaValue(val.Ha, val);
                    if (
                        isNumberInRange(val.year, range[value]) &&
                        areaUnitOrder[areaValue] < areaUnitOrder[initialChartUnits.area]
                    ) {
                        tempUnits.area = areaValue;
                    }
                });
            });
        } else {
            data[value].forEach((v) => {
                v.values.forEach((val) => {
                    const co2 = formatCO2eValue(val.MtCO2e, val);
                    if (
                        isNumberInRange(val.year, range[value]) &&
                        otherUnitOrder[co2] < otherUnitOrder[initialChartUnits[value]]
                    ) {
                        tempUnits[value] = co2;
                    }
                });
            });
        }
    });

    return tempUnits;
}