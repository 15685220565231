import { margin, width } from "../../constants";
import { scaleBand } from "d3-scale";

const d3 = {
    scaleBand,
};

export default function getXScale(valuesForCalculations) {
    return d3
        .scaleBand()
        .domain(valuesForCalculations.map((d) => d.year))
        .range([margin.left, width + margin.left])
        .padding(0);
}
