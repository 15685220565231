import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import { routesFlat } from "@routes";
import RowItem from "./RowItem";
import DeleteConfirmationModal from "./DeleteConfirmationModal";

const token = localStorage.getItem("token");

export default function NewsView() {
  const [articles, setArticles] = useState([]);
  const [confirmDelete, setConfirmDelete] = useState({ display: false });
  const navigate = useNavigate();

  useEffect(() => {
    fetch("/cms/v1/newsfeed/summary", {
      method: "GET",
      headers: {
        "Content-Type": "application/json",
        Authorization: `Bearer ${token}`,
      },
    })
      .then((resp) => resp.json())
      .then((arr) => setArticles(arr));
  }, []);

  const goBackHome = () => navigate(routesFlat.admin);

  const modalProps = { confirmDelete, setConfirmDelete };

  return (
    <div className="mb-20 mt-[80px] flex min-h-screen w-full justify-center">
      <div className="w-3/4 ">
        <div className="mb-10 mt-10 text-center text-6xl font-bold opacity-[10%]">
          Your News Articles
        </div>
        <div
          className="w-[160px] cursor-pointer rounded-lg bg-black px-4 py-2 text-center text-xs font-bold uppercase text-white "
          onClick={goBackHome}
        >
          {"< go back to home"}
        </div>
        <div className="mt-6 flex w-full flex-col flex-wrap items-center">
          {articles.length > 0 ? (
            articles.map((art) => {
              const rowProps = { ...art, setConfirmDelete };
              return <RowItem {...rowProps} />;
            })
          ) : (
            <div className="w-full">
              {Array(6)
                .fill(null)
                .map((i) => (
                  <Skeleton key={i} className="h-[60px] w-full" />
                ))}
            </div>
          )}
        </div>
      </div>
      <DeleteConfirmationModal {...modalProps} />
    </div>
  );
}
