import { useContext } from "react";
import { CMSContext } from "@";
import { Link } from "react-router-dom";
import { routesFlat } from "@routes";

export default function Home() {
  const { state } = useContext(CMSContext)

  const CMSEditOptions = [
    { id: "display-news", display: "View Articles", link: routesFlat.adminNewsDisplay },
    { id: "edit-news", display: "Add New Article", link: routesFlat.adminNewsEdit },
  ];

  const onSignOut = () => {
    localStorage.clear() 
    state.setToken('')
  }
  return (
    <div className="w-full h-screen flex flex-col items-center">
      <div className="w-3/4 mt-20">
        <div className="w-full flex justify-between">
          <div className="font-bold opacity-[10%] text-4xl italic text-center ">
            welcome to your cms panel
            {/* title */}
          </div>
          <button onClick={onSignOut}>sign out</button>
        </div>
        <div className="mt-20">
          {CMSEditOptions.map(({ display, link }) => {
            return (
              <Link to={link} className="hover:bg-green/25 border-[1px] rounded-xl px-20 py-10 mr-10">
                {display}
              </Link>
            );
          })}
        </div>
      </div>
    </div>
  );
}
