import React, { useState, useEffect } from "react";
import SearchInput from "../Input";
import ParentItem from "../ParentItem";
import Skeleton from "react-loading-skeleton";
import ctreesLogo from "@assets/ctrees-sm-logo.png";

const SearchContainer = ({ setSelection, displayText, setDisplayText }) => {
  const [searchTerm, setSearchTerm] = useState(""); // value user is inputing
  const [isOpen, setIsOpen] = useState(false); // open/closes the filtered search terms
  const [isDisplayText, setIsDisplayText] = useState(true); // determines if user is actively typing or there is a location to display
  const [filteredData, setFilteredData] = useState({}); // Initialize with all location data
  const [hasFilteredData, setHasFilteredData] = useState(false);
  const [currentIndex, setCurrentIndex] = useState(-1); // index when they use arrow keys to search
  const [searchData, setSearchData] = useState([]); // all search items
  const [searchDataLoaded, setSearchDataLoaded] = useState(false); // search items are loaded

  // user selects item from search query
  const updateSelection = (displayText, adminLevel, gid, userInteraction) => {
    setSelection(displayText, gid, adminLevel, userInteraction);
    setCurrentIndex(-1);
  };

  // user selects item from search query
  const handleSelectItem = (displayText, gid, adminLevel) => {
    setIsOpen(false);
    setIsDisplayText(true);
    setSearchTerm("");
    updateSelection(displayText, gid, adminLevel, "search");
  };

  function handleKeyDown(event, item) {
    if (event.key === "ArrowDown" || event.key === "ArrowUp") {
      event.preventDefault();
      navigateResults(event.key);

      // Disable scrolling during arrow key navigation
      document.getElementById("search-dropdown").style.overflowY = "hidden";
    }
  }

  function navigateResults(key) {
    const resultItems = document.querySelectorAll(".search-item");
    let currInx = currentIndex;

    if (key === "ArrowDown" && currentIndex < resultItems.length - 1) {
      currInx = currentIndex + 1;
      setCurrentIndex(currInx);
    } else if (key === "ArrowUp" && currentIndex > 0) {
      currInx = currentIndex - 1;
      setCurrentIndex(currInx);
    }

    if (resultItems[currInx]) {
      const displayText = resultItems[currInx].getAttribute("data-displaytext");
      setDisplayText(displayText);
      resultItems[currInx].focus();
    }
  }

  const handleKeyDownDropdown = (e, displayText, adminLevel, gid) => {
    if (e.key === "ArrowDown" || e.key === "ArrowUp") {
      e.preventDefault();
      navigateResults(e.key);

      // Disable scrolling during arrow key navigation
      document.getElementById("search-dropdown").style.overflowY = "hidden";
    }
    if (e.key === "Enter" || e.key === " ") {
      e.stopPropagation();
      handleSelectItem(displayText, adminLevel, gid);
    }
  };

  function handleMouseOver() {
    // Enable scrolling when the mouse is over the resultsContainer
    document.getElementById("search-dropdown").style.overflowY = "auto";
  }

  function handleMouseOut() {
    // Disable scrolling when the mouse leaves the resultsContainer
    document.getElementById("search-dropdown").style.overflowY = "hidden";
  }

  // filter on every keydown
  const filterInHierarchy = (items, searchTerm) => {
    if (!items) {
      return [];
    }

    if (!Array.isArray(items)) {
      return items;
    }

    return items
      .map((item) => ({
        ...item,
        similarityScore: calculateSimilarityScore(item, searchTerm),
        children: filterInHierarchy(item.children, searchTerm),
      }))
      .sort((a, b) => b.similarityScore - a.similarityScore) // Sort by similarity score, most similar items up top
      .filter((item) => item.similarityScore > 0);
  };

  // determines how similar the search term is to the item being searched
  const calculateSimilarityScore = (item, searchTerm) => {
    const name = item.name
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
    const searchTermLower = searchTerm
      .toLowerCase()
      .normalize("NFD")
      .replace(/\p{Diacritic}/gu, "");
    // prioritize similarity for country/territories first, then city
    const adminLevelBuffer = item.admin === 0 ? 3 : item.admin === 1 ? 2 : 0;

    if (name === searchTermLower) {
      return 3 + adminLevelBuffer; // Exact match at any level
    } else if (name.startsWith(searchTermLower)) {
      return 2 + adminLevelBuffer; // Partial match at the beginning of the name
    } else if (item.children && item.children.length > 0) {
      // if there is similarity of any children
      const childSimilarityScores = item.children.map((child) =>
        calculateSimilarityScore(child, searchTerm)
      );
      return Math.max(...childSimilarityScores);
    } else {
      return 0; // No match
    }
  };

  const updatedFilteredData = filterInHierarchy(searchData, searchTerm);

  async function getLocations() {
    const response = await fetch(
      "https://ctrees-website.s3.us-west-2.amazonaws.com/gis_admin/admin_name_tree.json"
    );
    return response;
  }

  useEffect(() => {
    const fetchSearchData = async () => {
      try {
        const response = await getLocations();

        if (response.ok) {
          const data = await response.json();
          // Set the data to the state or context for rendering
          setSearchData(data);
          setSearchDataLoaded(true);
        } else {
          console.error("Failed to fetch data.");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetchSearchData();
  }, []);

  useEffect(() => {
    if (searchTerm.trim() === "") {
      setIsOpen(false);
    } else {
      setIsOpen(true);
    }
  }, [searchTerm]);

  useEffect(() => {
    if (filteredData.length > 0) {
      setHasFilteredData(true);
    } else {
      setHasFilteredData(false);
    }
  }, [filteredData]);

  useEffect(() => {
    if (searchDataLoaded) {
      setHasFilteredData(true);
    }
  }, [searchDataLoaded]);

  const inputStyle =
    "w-full p-2 text-black text-l font-semibold leading-normal";
  const searchDropdownStyle =
    "border mt-2 rounded-lg bg-white max-h-96 overflow-y-auto";
  return (
    <div className="w-auto px-4 py-2 bg-white rounded-lg flex justify-between items-center z-50">
      <div className="w-full flex gap-5 items-center">
        <div className="w-full flex items-center gap-2">
          <a className="" href="https://ctrees.org" target="_blank">
            <img
              className="lg:block md:block hidden w-[130px]"
              src={ctreesLogo}
            />
          </a>
          <div className="text-black text-[10px] leading-[11px]">
            SELECTED <br />
            AREA
          </div>
          <div className="relative flex items-center w-full">
            <SearchInput
              filteredData={searchData}
              searchTerm={searchTerm}
              setSearchTerm={setSearchTerm}
              displayText={displayText}
              isDisplayText={isDisplayText}
              setIsDisplayText={setIsDisplayText}
              setDisplayText={setDisplayText}
              setFilteredData={setFilteredData}
              setIsOpen={setIsOpen}
              handleKeyDown={handleKeyDown}
              inputStyle={inputStyle}
            />
            {isOpen && searchTerm && (
              <div className="absolute top-full left-0 w-full bg-white border p-2 z-50">
                {!searchDataLoaded ? (
                  <div className={searchDropdownStyle}>
                    <Skeleton className="h-[50px] mb-2" count={6} />
                  </div>
                ) : (
                  <div>
                    {hasFilteredData ? (
                      <ParentItem
                        filteredData={updatedFilteredData}
                        handleMouseOver={handleMouseOver}
                        handleMouseOut={handleMouseOut}
                        handleKeyDown={handleKeyDownDropdown}
                        handleSelectItem={handleSelectItem}
                      />
                    ) : (
                      <div className="border mt-2 rounded-lg bg-white max-h-20">
                        <p>There is no data related to the search.</p>
                      </div>
                    )}
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default SearchContainer;
