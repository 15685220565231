export default function formatDatePicker(date) {
  const newDate = new Date(date);
  const formatDate =
    newDate.getDate() < 10 ? `0${newDate.getDate()}` : newDate.getDate();

    // have to add 1 to getMonth because it indexes from 0
    const month = newDate.getMonth() + 1
    const formatMonth = month < 10 ? `0${month}` : month;
  
  return [newDate.getFullYear(), formatMonth, formatDate].join("-");
}
