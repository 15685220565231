import { MultiRangeSlider, Legend, ChartFilter } from "./components";
import LineChart from "./LineChart";
import BarChart from "./BarChart";
import { emissionLineColors, otherLineColors, legendItems } from "./constants";


export default function Chart({
  onFilterChange,
  units,
  data,
  initialChartRange,
  xAxisLimits,
  barData,
  dataType,
}) {
  return (
    <>
      <MultiRangeSlider
        id={dataType}
        onFilterChange={onFilterChange}
        min={initialChartRange[`${dataType}`].min}
        max={initialChartRange[`${dataType}`].max}
      />
      <Legend legendItems={legendItems[`${dataType}`]} />
      <div className="relative">
        <ChartFilter
          data={data[`${dataType}`]}
          xAxisLimits={xAxisLimits[`${dataType}`]}
          chartType={barData ? "bar" : "line"}
        >
          {(data, xAxisLimits) =>
            barData ? (
              <BarChart
                id={"barchart"}
                data={barData}
                unit={units[`${dataType}`]}
                xAxisLimits={xAxisLimits}
              />
            ) : (
              <LineChart
                id={dataType}
                multiLineData={data}
                unit={units[`${dataType}`]}
                xAxisLimits={xAxisLimits[`${dataType}`]}
                lineColors={
                  dataType === "emission" ? emissionLineColors : otherLineColors
                }
              />
            )
          }
        </ChartFilter>
      </div>
    </>
  );
}