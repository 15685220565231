import React, { useState, useEffect } from "react";

function ChartFilter({ children, data, xAxisLimits, chartType = "line" }) {
  function updateXAxis(data, xAxisLimits) {
    const minX = xAxisLimits[0];
    const maxX = xAxisLimits[1];

    return data.map((series) => {
      // series.values will only exist for the line chart
      // this functionality will need to be revised for barchart filtering
      if (series.values && chartType === "line") {
        const filteredValues = series.values.filter(
          (d) => d.year >= minX && d.year <= maxX
        );
        return { ...series, values: filteredValues };
      } else {
        return { values: [2020, 2021] };
      }
    });
  }

  useEffect(() => {
    updateXAxis(data, xAxisLimits);
  }, [xAxisLimits]); 

  return <>{children(updateXAxis(data, xAxisLimits), xAxisLimits)}</>;
}

export default ChartFilter;
