export default function Heatmap({ link }) {
  return (
    <video
      loading="lazy"
      className="h-full w-[99.7%] object-cover"
      preload="none"
      controls={false}
      loop={true}
      autoPlay={true}
      muted={true}
    >
      <source src={link} type="video/mp4" />
    </video>
  );
}
