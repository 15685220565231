import React, { useEffect, useState, useRef } from "react";
import { select } from "d3-selection";
import { axisBottom } from "d3-axis";
import { Tooltip } from "../components";
import { otherLineColors } from "../constants";
import { width, height, margin } from "../constants";
import {
    getTooltipInitiation,
    initiateChart,
    addUnitLabelOnYAxis,
} from "../helpers";
import {
    getXScale,
    addXAxis,
    getMaxYValue,
    getYScale,
    addChartBackground,
    getTicksOnYAxis,
    addLabelsOnYAxis,
    addGridlinesToYAxis,
    generateBars,
} from "./helpers";

const d3 = {
    select,
    axisBottom,
};

const BarChart = ({ id, data, unit, xAxisLimits }) => {
    const chartContainerRef = useRef(null);
    const [tooltip, setTooltip] = useState(getTooltipInitiation(unit));

    useEffect(() => {
        if (d3.select(chartContainerRef.current)) {
            // Remove all child elements within the SVG or chart container
            d3.select(chartContainerRef.current).selectAll("*").remove();
        }

        /* INITIALIZE CHART */
        const svg = initiateChart(chartContainerRef, width, height, margin);

        /* X-AXIS and SCALES */
        const years = data.map((d) => d.year);
        const xScale = getXScale(years);
        const xAxis = d3.axisBottom(xScale);

        addXAxis(svg, xAxis);

        /* Y-AXIS and SCALES */
        const maxYValue = getMaxYValue(data);
        const yScale = getYScale(maxYValue);

        // Append a black background rectangle
        addChartBackground(svg, yScale);

        // Modify the y-axis to show labels only on the grid lines
        const yAxis = getTicksOnYAxis(yScale);

        addLabelsOnYAxis(svg, yAxis);

        // unit label on y-axis
        addUnitLabelOnYAxis(svg, unit);

        addGridlinesToYAxis(svg, yScale);

        /* GENERATE BARS */
        generateBars(svg, data, xScale, yScale, unit, setTooltip);
    }, [data, xAxisLimits]);

    return (
        <>
            <Tooltip {...tooltip} colors={otherLineColors} />
            <div
                ref={chartContainerRef}
                id={id}
                className="panelChart flex items-center justify-center"
            ></div>
        </>
    );
};

export default BarChart;
