import { useContext, useState } from "react";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import { PageHeader, OpacityWrapper, MetaTagHeader } from "../../components";
import { formatDateMonth } from "@helpers";
import { NewsContext } from "@";
import { staticTree, newsFilterOptions } from "@helpers/constants";
import NewsFilter from "./Filter";

const pageHeader = {
  title: "The Latest",
  desc: "Get the latest news, product updates and success stories from CTrees.",
  img: "https://ctrees-website.s3.us-west-2.amazonaws.com/website_images/static/heatmap/5.png",
};

const metaProps = {
  title: "News and updates on global forest carbon from CTrees",
  desc: "CTrees’ data products, science, and partnerships to protect and restore forests are constantly evolving. Learn more about our work.",
  img: "",
};

export default function News() {
  const { articles } = useContext(NewsContext);
  return (
    <>
      <MetaTagHeader {...metaProps} />
      <div className="w-full flex flex-col justify-center items-center">
        <PageHeader {...pageHeader} />
        {/* <NewsFilter /> */}
        <div className="flex flex-wrap border-t-[1px] w-full h-full border-black/[10%] justify-around p-10">
          {articles.length > 0 ? (
            articles.map((item, i) => (
              <>
                <ArticleItem item={item} key={item.title} />
                <div
                  key={i}
                  className="h-[550px] w-[1px] bg-gray nth-3n:hidden md:flex hidden"
                ></div>
              </>
            ))
          ) : (
            <div className="w-full flex flex-wrap">
              {Array(6)
                .fill(null)
                .map((i) => (
                  <Skeleton key={i} className="h-[200px] w-[100px]" />
                ))}
            </div>
          )}
        </div>
      </div>
    </>
  );
}

export function ArticleItem({ item, className = "" }) {
  const [imgLoaded, setImgLoaded] = useState(false);
  const date = formatDateMonth(item.publish_date);
  const articleTag = item.article_tags[0];
  const setImgLoad = (e) => {
    setImgLoaded(true);
  };

  return (
    <OpacityWrapper className="md:h-[550px] md:w-1/4 flex flex-col hover:scale-[1.01] group relative my-10 md:pb-20 px-4 md:px-0 items-center text-center">
      <Link
        to={`/news/${item.uri}-${item.id}`}
        className="flex flex-col justify-between h-full max-w-[400px]"
      >
        <div className="flex flex-col">
          <div className="h-[200px] w-full object-cover">
            {item.image_url ? (
              <>
                <img
                  src={item.image_url}
                  className="w-full h-full object-cover"
                  onLoad={setImgLoad}
                  loading="lazy"
                />
                {!imgLoaded && <Skeleton className="h-[200px] w-full" />}
              </>
            ) : (
              <img src={staticTree} className="w-full" />
            )}
          </div>
          <div className="font-extrabold text-lg mt-4">{item.title}</div>
          <div className="mt-2">{item.summary}</div>
        </div>
        <div className="flex justify-between lg:flex-row flex-col mt-6 items-center md:mt-0">
          <div className="mb-4 lg:mb-0 text-[darkgray] bg-black/[3%] uppercase text-sm py-[2px] lg:w-1/2">
            {date}
          </div>
          <div className={tagStyle + " lg:w-1/2"}>
            {articleTag ? newsFilterOptions.type[articleTag] : "read more"}
          </div>
        </div>
      </Link>
    </OpacityWrapper>
  );
}

export const tagStyle =
  "md:group-hover:bg-green/75 tracking-wide md:bg-green text-green md:text-white md:text-xs font-bold self-center px-4 py-1 uppercase";
