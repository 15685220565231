import { useEffect, useState } from "react";
import OpacityWrapper from "../OpacityWrapper";
import { PrimaryBtn } from "../Button";
import { signUp } from "@helpers";
import { heatmaps } from "@helpers/constants";
import Heatmap from "../Heatmap";

const btn = {
  link: "",
  className: "w-full",
};

function validEmail(email) {
  const isValidEmail = /^[\w-.]+@([\w-]+\.)+[\w-]{2,4}$/g;
  return email?.match(isValidEmail);
}

export default function StayConnected() {
  const [email, setEmail] = useState("");
  const [enabled, setEnabled] = useState(false);
  const [displayMsg, setDisplayMsg] = useState();
  const [checkbox, setCheckbox] = useState(false);

  const onSetEmail = (e) => {
    const input = e.target.value;
    const valid = validEmail(input);

    setEmail(input);

    if (valid && checkbox) {
      setEnabled(true);
    } else {
      setEnabled(false);
    }
  };

  const onCheckbox = () => {
    const valid = validEmail(email);
    if (valid && !checkbox) {
      setEnabled(true);
    }

    if (checkbox) {
      setEnabled(false);
    }

    setCheckbox(!checkbox);
  };

  const onClick = (e) => signUp(e, email, setDisplayMsg);

  useEffect(() => {
    if (displayMsg) {
      setTimeout(() => {
        setEmail("");
        setDisplayMsg(null);
      }, 7000);
    }
  }, [displayMsg]);

  const btnProps = { ...btn, onClick, enabled };

  const desktopHeatmap = "md:w-1/2 md:p-0 md:mt-0 md:px-0 ";
  const mobileHeatmap = " h-full w-auto mt-16 relative overflow-hidden";

  return (
    <OpacityWrapper className={parentContainer}>
      <div className={desktopHeatmap + mobileHeatmap}>
        <Heatmap link={heatmaps[1]} />
      </div>

      <div className="md:w-1/2 w-full py-20 px-10 md:py-0 md:mb-0 md:px-0 relative flex h-full justify-center">
        <div className="md:max-w-[400px] flex flex-col items-center h-full justify-center">
          <div className="text-center font-extrabold text-4xl">
            Stay Connected
          </div>
          <div className="text-center font-bold text-xl mt-10 md:mb-40 mb-20 tracking-1">
            Sign up for product updates and news from the CTrees team.
          </div>

          <div className="flex flex-col relative w-full">
            <div className="absolute top-0 text-white font-bold h-[50px] flex flex-col -mt-10 mb-4">
              {displayMsg && (
                <>
                  <div className="rounded-lg bg-green px-2 py-1">
                    {displayMsg}
                  </div>
                  <div className={displayMsgStyle}></div>
                </>
              )}
            </div>
            <input
              required
              type="email"
              name="EMAIL"
              id="mce-EMAIL"
              className={inputStyle + " required email"}
              placeholder="Enter your email"
              value={email}
              onChange={onSetEmail}
            />
          </div>
          <div className="text-xs flex flex-nowrap justify-start items-start mb-10">
            <input
              required
              type="checkbox"
              checked={checkbox}
              onChange={onCheckbox}
              className="mr-4"
            />
            <div>
              By submitting your email address, you agree to receive
              communications from CTrees. You can opt-out at any time.
            </div>
          </div>

          <div className="mt-10 w-full">
            <PrimaryBtn {...btnProps}>send</PrimaryBtn>
          </div>
        </div>
      </div>
    </OpacityWrapper>
  );
}

const displayMsgStyle =
  "w-[0px] border-t-[10px] border-x-[15px] border-t-green border-x-[transparent] ml-4";
const inputStyle =
  "focus:outline-none focus:ring-b focus:ring-blue w-full border-t-0 border-x-0 border-b-2" +
  " placeholder-slate-400 border-[1px] mb-4 contrast-more:placeholder-slate-500 py-3 px-4";
const parentContainer =
  "w-full md:h-screen text-black flex flex-col md:flex-row justify-center items-center border-t-[1px] border-black/[10%]";
