import { useContext } from "react";
import { NewsContext } from "@";
import { fullWidthSubHeader } from "..";
import { ArticleItem } from "../../news";

export default function Latest() {
  const { articles } = useContext(NewsContext);
  const latest = articles.slice(0, 3);

  return (
    <>
      <div className={fullWidthSubHeader + " text-4xl w-full"}>Latest</div>
      <div className="flex flex-wrap flex-col md:flex-row my-10 justify-around w-full">
        {latest?.map((item) => (
          <ArticleItem item={item} key={item.id} />
        ))}
      </div>
    </>
  );
}
