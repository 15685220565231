import { max } from "d3-array";

const d3 = { max };

export default function getMaxYValue(data) {
    return d3.max(data, (d) => {
        const values = Object.values(d).slice(1); // Extract values of components, skipping 'year'

        return d3.max(values); // max will return total always
    });
}
