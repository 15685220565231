
import { useState, useContext, useEffect } from "react";
import { Auth, Home } from "./pages";
import { CMSContext } from "@";

export default function CMS() {
  const loggedIn = localStorage.getItem("token");
  const [token, setToken] = useState(loggedIn);
  const { setState } = useContext(CMSContext)
  
  useEffect(() => setState({token, setToken}), [token])

  return <>{!!loggedIn ? <Home /> : <Auth />}</>;
}