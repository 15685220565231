import { height } from "../../constants";

export default function addLabelsToXAxis(svg, xAxis, maxValues) {
    // Add first and last label
    return svg
        .append("g")
        .attr("class", "x-axis")
        .attr("transform", `translate(0, ${height})`)
        .call(xAxis)
        .selectAll("text")
        .attr("class", "text-black")
        .style("fill", (d, i) =>
            i === 0 || i === maxValues - 1 ? "black" : "transparent",
        );
}
