import React, { useEffect, useRef, useState, useMemo } from "react";
import mapboxgl from "mapbox-gl";
import "mapbox-gl/dist/mapbox-gl.css";
import {
  addGeoJsonSource,
  outlineLayer,
  transparentLayer,
  removeSourceLayer,
  setLayerOrder,
  getAdmin0Code,
  isSameAdmin0,
  getAdmin0Data,
  getAdmin1Url,
  getAdmin0Children,
  getJmrvAdmin0Data,
  getJmrvAdmin1Data,
  getJmrvAdmin0Percent,
  getJmrvAdmin1Percent,
  moveToCoordinates,
  moveToCenterOfLayer,
  getAdmin0CenterCoordinates,
  getCoordinatesFromGeojson,
} from "../../../helpers";

// this comment and mapboxgl.workerClass NEED to be right under each other
mapboxgl.workerClass =
  // eslint-disable-next-line import/no-webpack-loader-syntax
  require("worker-loader!mapbox-gl/dist/mapbox-gl-csp-worker").default;

const Mapbox = ({
  updateData,
  setSelection,
  selectedAdmin,
  selectedGID,
  userInteraction,
}) => {
  const mapRef = useRef(null);
  const [map, setMap] = useState(null);
  const [mapLoaded, setMapLoaded] = useState(false);
  const adminLevelRef = useRef(selectedAdmin);
  const gidRef = useRef(selectedGID);
  const sameAdmin0Ref = useRef();
  const admin0gisUrl = useMemo(
    () => `/api/v1/gis/admin_0?gid_0=${getAdmin0Code(selectedGID)}`,
    [selectedGID]
  );
  const admin1gisUrl = useMemo(
    () => `/api/v1/gis/admin_1?gid_1=${selectedGID}`,
    [selectedGID]
  );

  const admin0 = 0;
  const admin1 = 1;
  const admin0Src = "admin-0-src";
  const admin0Layer = "admin-0-layer";
  const admin1Src = "admin-1-src";
  const admin1Layer = "admin-1-layer";
  const selectedOutline = "selected-outline";

  // Initialize Mapbox
  mapboxgl.accessToken =
    "pk.eyJ1IjoiYWFzaGFyeSIsImEiOiJjbG5leHZmODAwZ2FhMnJyaWxtZzZhem5tIn0.ybRQgfdnPYGeXrgbU65Xiw";

  /* ADD MAP LAYERS */

  const loadAdmin0Data = async (admin0gisUrl, selectedGID, selectedAdmin) => {
    const gid = selectedGID;
    const sameAdmin0 = sameAdmin0Ref.current;
    const map = mapRef.current;
    const adminLevel = selectedAdmin;

    const source = `admin-${adminLevel}-src`;
    const layer = `admin-${adminLevel}-layer`;

    // remove source and layer before adding new one
    await removeSourceLayer(map, selectedOutline, source);
    await removeSourceLayer(map, layer, source);

    updateData(await getJmrvAdmin0Data(gid), await getJmrvAdmin0Percent(gid));

    // admin 0 layer is added to outline a country/nation
    const data = await getAdmin0Data(gid);
    moveToCoordinates(map, [data.center_longitude, data.center_latitude], gid);
    await addGeoJsonSource(map, data.url, admin0Src);
    await transparentLayer(map, admin0Src, admin0Layer, "transparent");
    await outlineLayer(map, admin0Src, selectedOutline, "black");

    // admin 1 layer will come with click events for inside it's layer (state/territories)
    await addAdmin0ChildrenLayer(admin1Src, admin1Layer, sameAdmin0);

    setLayerOrder(map);
  };

  const loadAdmin1Data = async (admin1gisUrl, selectedGID, selectedAdmin) => {
    const gid = selectedGID;
    const sameAdmin0 = sameAdmin0Ref.current;
    const map = mapRef.current;
    const adminLevel = selectedAdmin;

    const source = `admin-${adminLevel}-src`;
    const layer = `admin-${adminLevel}-layer`;

    if (!sameAdmin0) {
      // remove source and layer before adding new one
      await removeSourceLayer(map, selectedOutline, source);
      await removeSourceLayer(map, layer, source);

      // admin 1 layer will come with click events for inside it's layer (state/territories)
      await addAdmin0ChildrenLayer(source, layer, sameAdmin0);
    }

    if (userInteraction === "search") {
      await removeSourceLayer(map, selectedOutline, source);

      const u = await getAdmin1Url(gidRef.current);
      moveToCenterOfLayer(map, await getCoordinatesFromGeojson(u));
    }

    filterAdmin1Layer(gid);
    setLayerOrder(map);
  };

  // the layer added here has all of the parent layer's state/territories
  // it's transparent until a user clicks to outline a child region
  async function addAdmin0ChildrenLayer(source, layer) {
    const map = mapRef.current;
    const gid = getAdmin0Code(gidRef.current);
    const sameAdmin0 = sameAdmin0Ref.current;
    const url = await getAdmin0Children(gid);

    if (!sameAdmin0) {
      await removeSourceLayer(map, layer, source);
    }
    await addGeoJsonSource(map, url, source);
    await transparentLayer(map, source, layer);

    map.on("click", layer, handleAdmin0ChildrenClick);
  }

  const handleAdmin0ChildrenClick = (e) => {
    const gid = e.features[0].properties.GID_1;
    const sameGid = gidRef.current === gid;

    if (!sameGid) {
      const name = e.features[0].properties.NAME_1;
      const country = e.features[0].properties.COUNTRY;
      const clickedCoords = [e.lngLat.lng, e.lngLat.lat];

      setSelection(`${name}, ${country}`, gid, 1, "click");
      moveViewToClickedLayer(
        mapRef.current,
        e.features[0].geometry.coordinates[0],
        clickedCoords
      );
    }
  };
  /* FILTERING */

  // this will outline an admin 1 polygon
  async function filterAdmin1Layer(gid) {
    updateData(await getJmrvAdmin1Data(gid), await getJmrvAdmin1Percent(gid));

    const map = mapRef.current;
    if (map.getLayer(selectedOutline)) {
      await map.removeLayer(selectedOutline);
    }
    await map.addLayer({
      id: selectedOutline,
      type: "line",
      source: admin1Src,
      filter: ["==", "GID_1", gid], // Filter to select the specific polygon
      layout: {},
      paint: {
        "line-color": "black",
        "line-width": 2,
      },
    });
  }

  function moveViewToClickedLayer(map, coordinates, clickedCoordinates) {
    try {
      var bounds = coordinates.reduce(function (bounds, coord) {
        return bounds.extend(coord);
      }, new mapboxgl.LngLatBounds(coordinates[0], coordinates[0]));

      map.fitBounds(bounds, {
        padding: 300,
      });
    } catch (e) {
      moveToCoordinates(map, clickedCoordinates, gidRef.current);
      console.log("Error on bound, moving to mouse event click.");
    }
  }

  /* INITIATION FUNTIONS */

  function onMapLoad(map) {
    map.on("load", async function () {
      setMapLoaded(true);

      // zoom in/zoom out
      var nav = new mapboxgl.NavigationControl();
      map.addControl(nav, "bottom-right");

      // carbon layer
      const carbonLayer = {
        id: "satellite-layer",
        type: "raster",
        source: {
          type: "raster",
          tiles: [
            "https://tileserver-biomass-2022.s3.us-west-2.amazonaws.com/{z}/{x}/{y}.png",
          ],
          tileSize: 256,
        },
      };
      map.addLayer(carbonLayer);

      addAdmin0Layer(map);
      loadAdmin0Data(admin0gisUrl, gidRef.current, adminLevelRef.current);

      map.on("error", function (e) {
        console.log("Map error:", e.error.message);
      });
    });
  }

  // global layer, needed to get map data to load and outline GID_1
  function addAdmin0Layer(map) {
    const countryBoundariesSrc = {
      type: "vector",
      url: "mapbox://aashary.gadm_v410_admin0_global",
    };
    const countryBoundariesLayer = {
      id: "country-boundaries-layer",
      type: "fill",
      source: countryBoundariesSrc,
      "source-layer": "gadm_v410_admin0_global",
      paint: {
        "fill-color": "transparent",
        "fill-opacity": 1,
      },
      minzoom: 0,
      maxzoom: 9,
    };
    const countryBoundariesOutlineLayer = {
      id: "country-boundaries-outline-layer",
      type: "line",
      source: countryBoundariesSrc,
      "source-layer": "gadm_v410_admin0_global",
      paint: {
        "line-color": "black",
        "line-width": 1,
        "line-opacity": 0.3,
      },
      interactive: false, // Make the layer non-clickable
    };
    map.addSource("country-boundaries-src", countryBoundariesSrc);
    map.addLayer(countryBoundariesLayer);
    map.addLayer(countryBoundariesOutlineLayer);

    map.on("click", "country-boundaries-layer", async function (e) {
      const gid = e.features[0].properties.GID_0;
      const name = e.features[0].properties.NAME_0;
      const sameCountry = isSameAdmin0(gidRef.current, gid);

      // if this layer is clicked then that means a new GID_1 was clicked (country)
      if (!sameCountry) {
        setSelection(`${name}`, gid, 0, "click");
      }
    });
  }

  // initate the map
  useEffect(() => {
    const map = new mapboxgl.Map({
      container: mapRef.current, // ID of container
      style: "mapbox://styles/mapbox/light-v11",
      center: [-95.712891, 37.09024], // USA (longitude, latitude)
      maxZoom: 9,
      minZoom: 3,
    });

    setMap(map);
    onMapLoad(map);

    // Clean up the map when the component unmounts
    return () => {
      map.remove();
    };
  }, []);

  // anytime GID is updated, we need to update the map outlines
  useEffect(() => {
    if (mapLoaded) {
      sameAdmin0Ref.current =
        getAdmin0Code(gidRef.current) == getAdmin0Code(selectedGID);
      adminLevelRef.current = selectedAdmin;
      gidRef.current = selectedGID;
    }
  }, [selectedGID, selectedAdmin, userInteraction]);

  useEffect(() => {
    if (mapLoaded && selectedAdmin === admin0) {
      loadAdmin0Data(admin0gisUrl, selectedGID, selectedAdmin);
    }

    return () => {
      if (mapRef.current) {
        // Remove the event listener and handle the event handlers
        mapRef.current.off("click", handleAdmin0ChildrenClick);
      }
    };
  }, [admin0gisUrl, selectedGID, selectedAdmin]);

  useEffect(() => {
    if (mapLoaded && selectedAdmin === admin1) {
      loadAdmin1Data(admin1gisUrl, selectedGID, selectedAdmin);
    }
  }, [admin1gisUrl, selectedGID, selectedAdmin]);

  useEffect(() => {
    if (map) {
      mapRef.current = map;
    }
  }, [map]);

  return <div id="map" ref={mapRef} className="absolute w-full h-full" />;
};

export default Mapbox;
