import { margin } from "../constants";

export default function addUnitLabelOnYAxis(svg, unit) {
    return svg
        .append("text")
        .attr("class", "unit-label")
        .attr("x", 50)
        .attr("y", -margin.top - 2)
        .attr("dy", "1em")
        .style("text-anchor", "middle")
        .style("fill", "#6B6B6B")
        .style("font-size", "8px")
        .text(unit);
}
