import { updateArticleState } from "../../../helpers";

export default function DeleteConfirmationModal({ confirmDelete, setConfirmDelete }) {
  const onCancel = () => setConfirmDelete({ display: false });
  
  const onDelete = () => {
    onCancel();
    updateArticleState(confirmDelete.id, 'DELETED');
  };
  
  return (
    <div
      className={`${
        confirmDelete?.display ? "absolute" : "hidden"
      } z-10 h-full w-full bg-black/50`}
    >
      <div className="h-full w-full flex justify-center items-center">
        <div className="h-1/4 p-4 rounded-md w-1/4 bg-white -mt-10 relative">
          <div className="">
            <div className="">
              Are you sure you want to delete?
            </div>
              <div className="font-bold text-sm line-clamp-3 mt-4">{confirmDelete?.title}</div>
            <div className="flex justify-around -ml-4 absolute bottom-0 mb-4 w-full">
              <div className={deleteConfirmBtn} onClick={onCancel}>
                Cancel
              </div>
              <div className={deleteConfirmBtn + " bg-red"} onClick={onDelete}>
                Confirm, Delete.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

const deleteConfirmBtn =
  "text-xs bg-gray/50 px-4 py-2 rounded-lg cursor-pointer w-[150px] text-center ";
