import React from "react";
import ChildItem from "../ChildItem";

const ParentItem = ({
  filteredData,
  handleMouseOver,
  handleMouseOut,
  handleSelectItem,
  handleKeyDown,
}) => {
  const searchDropdownStyle =
    "border mt-2 rounded-lg bg-white max-h-96 overflow-y-auto";
  const hoverStyle =
    "search-item hover:bg-gray focus:bg-gray hover:rounded focus:rounded";
  const topLevelStyle = `p-2 border-b border-gray-300 cursor-pointer`;

  return (
    <ul
      id="search-dropdown"
      className={searchDropdownStyle}
      onMouseOver={() => handleMouseOver()}
      onMouseOut={() => handleMouseOut()}
    >
      {filteredData.map((item) => (
        <li key={item.id} className={topLevelStyle} tabIndex={0}>
          <div
            className={`font-semibold text-lg pl-1 ${hoverStyle}`}
            data-displaytext={`${item.name}`}
            tabIndex={1}
            onClick={(e) => {
              e.stopPropagation();
              handleSelectItem(item.name, item.admin, item.id);
            }}
            onKeyDown={(e) => {
              handleKeyDown(
                e,
                `${item.name}`,
                item.admin,
                item.id
              );
            }}
            aria-label={item.name}
            role="button"
          >
            {item.name}
          </div>
          {item.children && item.children.length > 0 && (
            <ChildItem
              data={item}
              handleSelectItem={handleSelectItem}
              handleKeyDown={handleKeyDown}
              displayText={`${item.name}`}
              label={"States"}
              textStyle={`font-semibold text-md pl-1 ${hoverStyle}`}
            />
          )}
        </li>
      ))}
    </ul>
  );
};

export default ParentItem;
