import { useState } from "react";
import { Link } from "react-router-dom";
import DeleteIcon from "@mui/icons-material/DeleteOutlined";
import EditIcon from "@mui/icons-material/Edit";
import { routesFlat } from "@routes";
import { updateArticleState, formatDatePicker } from "../../../helpers";

export default function RowItem(article) {
  const { publish_date, title, id, state, setConfirmDelete } = article;
  const [selected, setSelected] = useState(state);
  const toEdit = routesFlat.adminNewsEdit + "/" + id;
  const currentDate = formatDatePicker(publish_date);

  const onSelectedDropdown = (e) => {
    setSelected(e.target.value);
    updateArticleState(id, e.target.value)
  };

  const onDeleteConfirm = () => {
    setConfirmDelete({ display: true, id, title });
  };
  return (
    <div className={rowStyle}>
      <div className="font-bold text-sm truncate w-[500px]">
        {title}
        <div className="text-[10px]">{currentDate}</div>
      </div>
      <div className="w-[300px] flex items-center justify-around">
        <select
          id="draftOrPublish"
          value={selected}
          onChange={onSelectedDropdown}
          className="focus:outline-none text-xs cursor-pointer"
        >
          <option value="PUBLISHED">Published</option>
          <option value="DRAFT">Draft</option>
        </select>
        <div className="self-end flex">
          <Link
            to={toEdit}
            className="text-xs font-bold mr-5 p-2 rounded-full hover:bg-lightgray/50"
          >
            <EditIcon />
          </Link>
          <div
            onClick={onDeleteConfirm}
            className="text-xs font-bold p-2 rounded-full hover:bg-lightgray/50"
          >
            <DeleteIcon />
          </div>
        </div>
      </div>
    </div>
  );
}

const rowStyle =
  "w-full py-4 px-2 mr-10 items-center flex justify-between shadow-md" +
  " first:rounded-t-md last:rounded-b-md border-x-2 border-t-2 last:border-b-2";
