// modify the title and desc attrs
export const header = {
  title: "Activity Data",
  desc: "See deforestation, forest degradation, and forest regeneration across the tropics",
  img: "https://ctrees-website.s3.amazonaws.com/website_images/product/activity_pg.jpg",
};

// for each feature, add as an object item with title and desc attr inside of featsAndBenefits array
export const featsAndBenefits = [
  {
    title: "Availability",
    desc: "Datasets mapping deforestation, forest degradation, and regeneration are available through partnerships and by license.",
  },
  {
    title: "Tropical coverage",
    desc: "See data for the world’s tropical moist forests.",
  },
  {
    title: "Precise attributions",
    desc: "First-of-its-kind AI technology identifies deforestation and forest degradation from selective and illegal logging, fire, and other activities with >90% overall accuracy.",
  },
  {
    title: "Historical perspective",
    desc: "Analyze trends at 5 meter resolution for 2017 to present, and at 30 meter resolution for 1990 to present.",
  },
];

// for each paragraph in methodology description, add as string item inside of method array
export const desc = [
  "CTrees has developed two sets of activity data: 1) pantropic maps of deforestation and degradation for 2017 to present, based on Planet satellite imagery made available through Norway’ International Climate and Forests Initiative (NICFI); and 2) pantropic maps of deforestation and secondary forest regeneration for 1990 to present, based on NASA’s Landsat imagery. CTrees activity datasets available in 2023 cover all tropical moist forest biomes – and will be extended to tropical dry forests and woodlands in 2024.",
  "CTrees’ pantropical maps based on Planet NICFI optical imagery at 5 meter spatial resolution use U-Net deep learning AI models developed and published in peer-reviewed journals by CTrees research scientists [1][2]. To detect deforestation, we employ a novel technique to automatically generate training samples of forest and deforested land to train the deep-learning model that produces the deforestation maps. To map degradation,  we create training samples based on the visual interpretation of Planet NICFI imagery, which are used to train the model to detect and classify the extent of degraded areas associated with logging activities, fire, and roads. Our AI models are trained to identify spatial patterns with >90% accuracy, including 1) forest and non-forest cover, 2) selective logging (treefall gaps, logging decks, and trails), 3) fire (burned land and forest), and 4) roads and unpaved trails associated with land use activities in the forest. The pan-tropical deforestation and degradation maps are produced bi-annually from 2017 to present. When compared to locally verified products, the maps outperform all other existing pan-tropical forest cover change data. CTrees’ NICFI-based data products are designed to facilitate sustainable development and are available exclusively for non-commercial uses.",
  "CTrees’ pantropical maps of deforestation and secondary forest regeneration from 1990 to present are based on NASA’s Landsat imagery at 30 meter resolution. The methodology is based on available data and resources on Google Earth Engine, machine learning algorithms developed by CTrees research scientists [3], and extensive validation of maps using statistical samples from high-resolution images and local expertise. Advantages of CTrees activity data are the long time series and accurate attributions of land use activities. Specifically the dataset detects area and the age of forest regeneration due to  land abandonment, restoration, and afforestation. The maps will be made available through CTrees’ dedicated platform in mid-2024 for both commercial and non-commercial use.",
];
// for each numbered reference under methodology, add as string item inside of superscript array
export const superscript = [
  "[1] Wagner, F. H., Dalagnol, R., Silva-Junior, C. H., ... & Saatchi, S. (2023). Mapping tropical forest cover and deforestation with Planet NICFI satellite images and deep learning in Mato Grosso State (Brazil) from 2015 to 2021. Remote Sensing, 15(2), 521.",
  "[2] Dalagnol, R.; Wagner, F. H.; ... & Saatchi, S. Mapping Tropical Forest Degradation with Deep Learning and Planet NICFI Data. Remote Sensing of Environment 2023, 298, 113798. https://doi.org/10.1016/j.rse.2023.113798.",
  "[3] Silva Junior, C. H., Heinrich, V. H., Freire, A. T., Broggio, I. S., Rosan, T. M., Doblas, J., ... & Aragão, L. E. (2020). Benchmark maps of 33 years of secondary forest age for Brazil. Scientific data, 7(1), 269.",
];
